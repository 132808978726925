/* eslint-disable array-callback-return */
import { ENUM_STATE } from '../../../../utils/enums'
import { database, storage } from '../../../firebase'

export const getBannersAPI = () => (dispatch) => {
    const url = database.ref('banners')
    return new Promise((resolve, reject) => {
      url.on('value', function(snapshot) {
        const value = []
        if(snapshot.val() != null) {
          Object.keys(snapshot.val()).map(key => {
            value.unshift({
              id: key,
              data: snapshot.val()[key]
            })
          })
          dispatch({type: 'CHANGE_BANNERS', value: value})
          resolve(value)
        } else {
          reject(false)
        }
      })
    })
  }

export const getActiveBannersAPI = () => (dispatch) => {
    const url = database.ref('banners')
    return new Promise((resolve, reject) => {
      url.orderByChild('state').equalTo(ENUM_STATE.ACTIVE).limitToLast(5).on('value', function(snapshot) {
        const value = []
        if(snapshot.val() != null) {
          Object.keys(snapshot.val()).map(key => {
            value.unshift({
                id: key,
                data: snapshot.val()[key]
              })
          })
          dispatch({type: 'CHANGE_BANNERS', value: value})
          resolve(value)
        } else {
          reject(false)
        }
      })
    })
  }

export const addBannerDataAPI = (data) => (dispatch) => {
    database.ref('banners/').push({
      img_url: data.img_url,
      title: data.title,
      description: data.description,
      redirect_url: data.redirect_url,
      state: data.state
    })
  }

export const addBannerImage = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const url = storage.ref('banners/'+data.name).put(data);
    url.on(
      "state_changed",
      snapshot => {},
      error => {
        reject(false)
      },
      () => {
        storage.ref('banners').child(data.name).getDownloadURL().then(
          url => {
            resolve(url)
          }
        )
      }
    )
  })
}

export const toggleBannerAPI = (data) => (dispatch) => {
    const url = database.ref('banners/'+ data.id)
    const newState = ( data.data.state === ENUM_STATE.ACTIVE ? ENUM_STATE.INACTIVE : ENUM_STATE.ACTIVE )
    const newValue = {
        img_url: data.data.img_url,
        title: data.data.title,
        description: data.data.description,
        redirect_url: data.data.redirect_url,
        state: newState
      }
    return new Promise((resolve, reject) => {
      url.set(newValue, (err) => {
        if( err ) {
          reject(false)
        } else {
          resolve(true)
        }
      })
    })
  }

import React from 'react';

const Input = ({onChange, id, label, type, placeholder, value, model, checked, changingValue, disabled, invalid}) => {
  if (type==='file'){
    if(model==='banner'){
      return(
        <div className="flex m-5 items-center justify-center bg-grey-lighter">
        <label className="w-96 h-40 flex flex-col items-center px-10 py-10 bg-white text-blue-500 shadow-lg tracking-wide uppercase border border-blue-400 cursor-pointer hover:bg-blue-500 hover:text-white">
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="mt-2 text-base leading-normal">Select a file</span>
            <input id={id} type='file' className="hidden" onChange={onChange}/>
            </label>
        </div>
      )
    }
    if(model==='button'){
      return(
        <div className="flex m-5 items-center justify-center bg-grey-lighter">
        <label className="w-32 h-10 flex flex-col items-center bg-white text-blue-500 shadow-lg tracking-wide border border-blue-400 cursor-pointer hover:bg-blue-500 hover:text-white">
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="text-sm">{label || "Select a file"}</span>
            <input id={id} type='file' className="hidden" onChange={onChange}/>
          </label>
        </div>
      )
    }
    return(
      <div className="flex m-5 items-center justify-center bg-grey-lighter">
      <label className="w-72 h-80 flex flex-col items-center px-16 py-24 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-400 cursor-pointer hover:bg-blue-500 hover:text-white">
          <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          <span className="mt-2 text-base leading-normal">Select a file</span>
          <input id={id} type='file' className="hidden" onChange={onChange}/>
          </label>
      </div>
    )
  }
  if (type==='date'){
    return(
      <div>
        <label className="block font-semibold mt-2"/>{label}
        <input id={id}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md"></input>
      </div>
    )
  }
  if (type==='search'){
    return(
      <div className="flex justify-center pt-5">
        <input className="bg-purple-white shadow rounded-md border-0 p-3 w-2/3 focus:outline-none focus:ring-1 focus:ring-blue-600" 
          id={id}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
        ></input>
      </div>
    )
  }
  if (type==='number'){
    return(
      <div className="w-24">
        <label className="block font-semibold mt-2"/>{label}
        <input id={id}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md"></input>
      </div>
    )
  }
  if (type==='checkbox'){
    return(
      <div className="flex mt-5">
        <input id={id} type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" checked={checked} onChange={onChange}/>
        <label className="block font-semibold ml-1"/>{label}
      </div>
    )
  }
  if (type==='radio'){
    return(
      <div class="flex items-center mr-4">
        <input id={id} 
          type="radio" 
          value={value}
          name={id}
          checked={checked}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={onChange}
          />
        <label for={id} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
      </div>
    )
  }
  if(changingValue){
    return (
      <div>
        <label className="block font-semibold mt-2"/>{label}
        <input id={id}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md text-black"></input>
      </div>
    )
  }
  if(disabled){
    return (
      <div>
        <label className="block font-semibold mt-2"/>{label}
        <input id={id}
          type={type}
          value={value}
          className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md text-black" disabled></input>
      </div>
    )
  }
  if(invalid){
    return (
      <div>
        <label className="block font-semibold mt-2"/>{label}
        <input id={id}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          defaultValue={value}
          className="border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 ring-red-600 bg-red-100 rounded-md text-black"></input>
      </div>
    )
  }
  return (
    <div>
      <label className="block font-semibold mt-2"/>{label}
      <input id={id}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        defaultValue={value}
        className="border w-full px-3 py-2.5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md text-black"></input>
    </div>
  )
}

export default Input;
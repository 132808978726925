export const toIDR = (number) => {
    if(number === undefined){
      return '0'
    }

    if(number === '-' ){
      return '-'
    }

    let regExp = /[a-zA-Z]/g
    if(regExp.test(number.toString())){
      return '0'
    }
    // return number.toString().replace(".","").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    const raw = number.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
    const formatter = new Intl.NumberFormat("ru", {
      style: "currency",
      currency: "IDR"
    })
    const result = formatter.format(raw).toString().slice(0,-7).trim().replace(/\s+/g,".")
    return result
  }

export const multiplyIDR = (IDRValue, multiplier) => {
  if(IDRValue === undefined || multiplier === undefined){
    return '0'
  }
  const raw = IDRValue.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
  return toIDR(Number(raw) * multiplier)
}

export const sumIDR = (IDRValue1, IDRValue2) => {
  if(IDRValue1 === undefined || IDRValue2 === undefined){
    return '0'
  }
  const raw1 = IDRValue1.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
  const raw2 = IDRValue2.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
  return toIDR(Number(raw1) + Number(raw2))
}

export const substractIDR = (IDRValue1, IDRValue2) => {
  if(IDRValue1 === undefined || IDRValue2 === undefined){
    return '0'
  }
  const raw1 = IDRValue1.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
  const raw2 = IDRValue2.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
  return toIDR(Number(raw1) - Number(raw2))
}
import { validateEmail } from "../../../../utils/validateFormat"
import { firestore } from "../../../firebase";

export const subscribeEmail = async (email) => {
  const validation = validateEmail(email)
  if (validation === null){
    return false;
  }
  try {
    const ref = firestore.collection('subscribers').doc(email)
    await ref.set({
      'status': true,
      'timestamp': new Date().toString()
    })
  } catch (error) {
    return false;
  }
  return true;
}

export const submitSubscribeTour = async (data) => {
  const validation = validateEmail(data.email)
  if (validation === null){
    return false;
  }
  try {
    const ref = firestore.collection('subscribeTour').doc(data.email)
    data.timestamp = new Date().toString()
    await ref.set(data)
  } catch (error) {
    return false;
  }
  return true;
}
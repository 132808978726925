import React from 'react';

const Button = ({onClick, title, loading, model, id, color, disabled}) => {
  if (loading) {
    return (
      <button id={id} className="bg-gray-500 text-white py-2 px-6 rounded-lg disabled:opacity-50" disabled>Loading...</button>
    )
  }

  if (disabled) {
    return (
      <button id={id} className="bg-gray-500 text-white py-2 px-6 rounded-lg disabled:opacity-50" disabled>{title}</button>
    )
  }

  if (model==='inactive') {
    return (
      <button id={id} className="bg-pink-800 hover:bg-pink-400 text-white py-2 px-6 rounded-lg focus:outline-none" onClick={onClick}>{title}</button>
    )
  }

  if (model==='reverse') {
    return (
      <button id={id} className="hover:bg-blue-400 text-white py-2 px-6 rounded-lg focus:outline-none text-blue-500 border-2 border-blue-400" onClick={onClick}>{title}</button>
    )
  }

  if (color==='yellow') {
    return (
      <button id={id} className="text-gray-900 bg-yellow-400 hover:bg-yellow-200 py-2 px-6 rounded-lg focus:outline-none" onClick={onClick}>{title}</button>
    )
  }
  
  return (
    <button id={id} className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-6 rounded-lg focus:outline-none" onClick={onClick}>{title}</button>
  )
}

export default Button;
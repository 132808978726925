import React, { Component, Fragment } from 'react';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import Textarea from '../../../../components/atoms/Textarea';
import { addBannerDataAPI, addBannerImage, getBannersAPI, toggleBannerAPI } from '../../../../config/redux/action/banner';
import { toggleAlert } from '../../../../config/redux/action';
import { connect } from 'react-redux';
import { ENUM_STATE } from '../../../../utils/enums';
import Alert from '../../../../components/atoms/Alert';
import { ADMIN_USERS } from '../../../../utils/admin_users';

class ExclusiveBanner extends Component {
  componentDidMount() {
    const { getBanners } = this.props;
    getBanners();
  }

  state = {
    image: '',
    url: '',
    description: '',
    title: '',
    redirect_url: ''
  }

  handleChange = (e) => {
    if(e.target.files[0]){
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2){
          this.setState({
            image: e.target.files[0],
            url: reader.result
          })
        }
      } 
      reader.readAsDataURL(e.target.files[0])
    }
  }

  handleChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleUpload = () => {
    const { image, description, redirect_url, title } = this.state;
    const { uploadImage, addBanner } = this.props;

    uploadImage(image).then(function(res) {
      const data = {
        img_url: res,
        title: title,
        description: description,
        redirect_url: redirect_url,
        state: ENUM_STATE.ACTIVE
      }
      addBanner(data)
    });
    this.handleCancel();
    this.handleSuccessAlert();
  }

  handleCancel = () => {
    this.setState({
      image: '',
      url: '',
      description: '',
      title: '',
      redirect_url: ''
    })
  }

  handleToggle = (banner) => {
    const { toggleBanner } = this.props
    // TODO HANDLE TOGGLE
    toggleBanner(banner)
    this.handleSuccessAlert()
  }

  handleSuccessAlert = () => {
    const { toggleAlert } = this.props
    const alertData = {
      show: true,
      words: 'Data has been saved successfully',
      type: 'SUCCESS'
    }
    toggleAlert(alertData)
    setTimeout(function(){ toggleAlert({ show: false }) }, 2000);
  }

  validateUserAccess = () => {
    const { history } = this.props;
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData === null) {
      history.push('/');
    }
    else if (!ADMIN_USERS.includes(userData.email)) {
      history.push('/');
    }
  }
  
  render() {
    const { handleChange, handleUpload, handleCancel, handleToggle } = this;
    const { image, url, description, redirect_url, title } = this.state;
    const { bannerList, showAlert } = this.props;
    this.validateUserAccess()

    return(
      <div className="text-gray-800">
        <Alert show={showAlert.show} words={showAlert.words} type={showAlert.type}/>
        <div className="w-full bg-blue-50 pb-5">
          <div className="flex justify-center items-center">
            {
              image ? (
                <Fragment>
                  <img className="border border-blue-400 w-96 h-40 m-5 object-cover" src={ url } alt='Destination'/>
                </Fragment>
              ) : (
                <Fragment>
                  <Input type="file" model="banner" onChange={(e) => handleChange(e)}/>
                </Fragment>
              )
            }
            <div className="w-2/4 mb-5">
              <Input id="title" placeholder="Title" onChange={this.handleChangeText} value={title}/>
              <Textarea id="description" placeholder="Description" size="medium" onChange={this.handleChangeText} value={description}/>
              <Input id="redirect_url" placeholder="Redirect URL" onChange={this.handleChangeText} value={redirect_url}/>
            </div>
          </div>
          <div className="flex justify-center space-x-4">
              <Button onClick={handleUpload} title="UPLOAD"/>
              <Button onClick={handleCancel} title="CANCEL" model="inactive"/>
          </div>
        </div>
        <div className="flex justify-center mt-10 mb-10">
          <table className="table-fixed border-2 w-11/12 border-gray-800">
            <thead className="border-2 border-gray-800">
              <tr>
                <th className="w-2/3">Banner</th>
                <th className="w-1/2 border-2 border-gray-800">Title</th>
                <th className="w-1/2 border-2 border-gray-800">Desc</th>
                <th className="w-1/2 border-2 border-gray-800">Redirect URL</th>
                <th className="w-1/4">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                bannerList.map(banner => {
                  return (
                    <tr key={banner.id}>
                      <td className="border-2 border-gray-800 p-2">
                        <img className="w-96 h-40 object-cover m-auto" src={banner.data.img_url} alt='Banner'/>
                      </td>
                      <td className="border-2 border-gray-800 p-2">{banner.data.title}</td>
                      <td className="border-2 border-gray-800 p-2">{banner.data.description}</td>
                      <td className="border-2 border-gray-800 p-2">{banner.data.redirect_url}</td>
                      <td className="border-2 border-gray-800 p-2">
                        {
                          banner.data.state === ENUM_STATE.ACTIVE ? (
                            <Button title="Deactivate!" model="inactive" onClick={() => handleToggle(banner)}/>
                          ) : (

                        <Button title="Activate!" onClick={() => handleToggle(banner)}/>
                          )
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  showAlert   : state.showAlert,
  bannerList  : state.banners
})

const reduxDispatch = (dispatch) => ({
  uploadImage       : (data) => dispatch(addBannerImage(data)),
  addBanner         : (data) => dispatch(addBannerDataAPI(data)),
  getBanners        : ()  => dispatch(getBannersAPI()),
  toggleBanner      : (data) => dispatch(toggleBannerAPI(data)),
  toggleAlert         : (data) => dispatch(toggleAlert(data))
})

export default connect(reduxState, reduxDispatch)(ExclusiveBanner);
import DatePicker from 'react-datepicker'
import { forwardRef, useEffect, useState } from 'react'

import "react-datepicker/dist/react-datepicker.css";

const CustomDatepicker = ({onPick, limitDate=true, initialSelectedDate=null}) => {
  let startDate
  let minDate
  let maxDate
  
  if(limitDate){
    startDate = new Date(Date.now() + ( 3600 * 1000 * 24 * 2));
    minDate = new Date(Date.now() + ( 3600 * 1000 * 24 * 2));
    maxDate = new Date(Date.now() + ( 3600 * 1000 * 24 * 180));
  }else{
    startDate = new Date(Date.now());
    minDate = new Date(Date.now() - ( 3600 * 1000 * 24 * 1000));
    maxDate = new Date(Date.now() + ( 3600 * 1000 * 24 * 1000));
  }

  const [selectedDate, setSelectedDate] = useState(startDate);

  useEffect(() => {
    if(initialSelectedDate?.seconds){
      setSelectedDate(new Date(initialSelectedDate?.seconds * 1000))
    }
  },[initialSelectedDate])

  useEffect(()=> {
    onPick(selectedDate)
  },[selectedDate])

 
  return (
    <div>
      <DatePicker 
        selected={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="dd/MM/yyyy"
        onChange={(date) => setSelectedDate(date)}
        className="border h-5 px-3 py-5 mt-1 w-full hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md text-black"
        />
    </div>
  );
}

export default CustomDatepicker
import React, { Component } from 'react';
import SliderBanner from '../../organisms/SliderBanner';
import TourCart from '../../organisms/TourCart';
import { connect } from 'react-redux'
import { ENUM_STATE } from '../../../utils/enums';
import DestinationCart from '../../organisms/DestinationCart';
import { getLatestActiveToursDataAPI } from '../../../config/redux/action/tour';
import { getLatestActiveDestinationsAPI } from '../../../config/redux/action/destination';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BlogPost from '../../organisms/BlogPost';

class Dashboard extends Component {
  state = {
    posts: []
  }

  componentDidMount() {
    const { getDestinations, getTours } = this.props;
    getDestinations();
    getTours();
    this.getArticles();
  }

  getArticles = async () => {
    const resp = await axios(`https://blog.jelajahholiday.com/wp-json/wp/v2/posts?per_page=6`)
    this.setState({
      posts: resp.data
    })
  }
  
  render() {
    const { destinationList, tourList } = this.props;
    return (
      <div>
        <div className="w-full">
          <SliderBanner/>
        </div>
        <div className="w-full pt-5">
          <div className="w-full mb-5">
            <h1 className="text-center text-gray-900 text-3xl md:text-4xl md:pt-5">Tour Package</h1>
          </div>
          <div className="flex overflow-x-scroll hide-scroll-bar md:overflow-visible md:flex-wrap md:justify-center m-auto">
              {
                tourList.length > 0 ? (
                  <div className="flex flex-nowrap md:flex-wrap md:justify-center">
                    {
                      tourList.map(tour => {
                        return (
                          <div key={tour.id}>
                            <TourCart key={tour.id}
                              id={tour.id}
                              tour={tour.data}
                              />
                          </div>
                        )
                      })
                    }
                  </div>
                ) : null     
              }
          </div>
          <div className="w-full pb-10 mt-5">
            <Link to="/our-tours">
              <div className="w-44 md:w-56 bg-yellow-400 m-auto rounded-lg cursor-pointer hover:bg-yellow-200">
                <h1 className="text-center text-gray-800 text-xl md:text-2xl py-2 px-4 hover:text-gray-900">Lihat Semua</h1>
              </div>
            </Link>
          </div>
        </div>
        
        <div className='w-full'>
          <div class="border-t border-gray-400 my-4 w-5/6 m-auto"></div>
        </div>
        
        <div className="w-full">
          <div className="w-full mb-5">
            <h1 className="text-center text-gray-900 text-3xl md:text-4xl pt-5">Tour Destination</h1>
          </div>
          <div className="flex overflow-x-scroll hide-scroll-bar md:overflow-visible md:flex-wrap md:justify-center m-auto">
            {
              destinationList.length > 0 ? (
                <div className="flex flex-nowrap md:flex-wrap md:justify-center">
                  {
                    destinationList.map(destination => {
                      return (
                        <div key={destination.id}>
                          {
                            destination.data.state === ENUM_STATE.ACTIVE ? (
                              <div className="flex flex-col m-5">
                                <DestinationCart destination={destination} key={destination.id}/>
                              </div>
                            ) : null
                          }
                        </div>
                      )
                    })
                  }
                </div>
              ) : null
            }
          </div>
          <div className="w-full pb-10 mt-5">
            <Link to="/destinasi-wisata">
              <div className="w-44 md:w-56 bg-blue-500 m-auto rounded-lg cursor-pointer hover:bg-blue-300">
                <h1 className="text-center text-gray-100 text-xl md:text-2xl py-2 px-4 hover:text-gray-900">Lihat Semua</h1>
              </div>
            </Link>
          </div>

          <div className='w-full'>
            <div class="border-t border-gray-400 my-4 w-5/6 m-auto"></div>
          </div>

          <div className='w-full'>
            <div className="w-full mb-5">
              <h1 className="text-center text-gray-900 text-3xl md:text-4xl pt-5">Recent Blog</h1>
            </div>

            <div className="md:flex md:overflow-visible md:flex-wrap md:justify-center m-auto">
              {
                this.state.posts.map(post => {
                  console.log('POST', post)
                  return(
                    <div className='m-4'>
                      <BlogPost 
                        thumbnail={post.yoast_head_json.og_image[0].url}
                        title={post.title.rendered}
                        description={post.yoast_head_json.og_description}
                        blogUrl={post.link}/>
                    </div>
                  )
                })
              }              
            </div>

            <div className="w-full pb-10 mt-5" onClick={() => {
              window.location.href = 'https://blog.jelajahholiday.com'
            }}>
              <div className="w-44 md:w-56 bg-yellow-400 m-auto rounded-lg cursor-pointer hover:bg-yellow-200">
                <h1 className="text-center text-gray-800 text-xl md:text-2xl py-2 px-4 hover:text-gray-900">Lihat Semua</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  destinationList  : state.destinations,
  bannerList  : state.banners,
  tourList    : state.tours
})

const reduxDispatch = (dispatch) => ({
  getDestinations     : () => dispatch(getLatestActiveDestinationsAPI()),
  getTours            : () => dispatch(getLatestActiveToursDataAPI())
})

export default connect(reduxState, reduxDispatch)(Dashboard);
import firebase, { database, storage } from '../../firebase'

export const toggleAlert = (data) => (dispatch) => {
  dispatch({type: 'CHANGE_SHOWALERT', value: data})
}

export const toggleShowLoginModal = (data) => (dispatch) => {
  dispatch({type: 'CHANGE_SHOWLOGINMODAL', value: data})
}

export const registerUserAPI = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({type: 'CHANGE_ISLOADING', value: true})
    firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
    .then((userCredential) => {
      // Signed in 
      // var user = userCredential.user;
      dispatch({type: 'CHANGE_ISLOADING', value: false})
      resolve(true)
    })

    .catch((error) => {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      dispatch({type: 'CHANGE_ISLOADING', value: false})
      reject(false)
    })
  }) 
}

export const loginUserAPI = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({type: 'CHANGE_ISLOADING', value: true})
    firebase.auth().signInWithEmailAndPassword(data.email, data.password)
      .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
        const userData = {
          email: user.email,
          uid: user.uid,
          emailVerified: user.emailVerified,
          refreshToken: user.refreshToken,
          usename: user.displayName
        }
        dispatch({type: 'CHANGE_ISLOADING', value: false})
        dispatch({type: 'CHANGE_ISLOGIN', value: true})
        dispatch({type: 'CHANGE_USER', value: userData})
        resolve(userData)
      })
      .catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        dispatch({type: 'CHANGE_ISLOADING', value: false})
        dispatch({type: 'CHANGE_ISLOGIN', value: false})
        reject(false)
      })
  })
}



export const addNotesDataAPI = (data) => (dispatch) => {
  database.ref('notes/' + data.userId).push({
    title: data.title,
    content: data.content,
    date: data.date
  })
}

export const getNotesDataAPI = (data) => (dispatch) => {
  const url = database.ref('notes/' + data.userId)
  return new Promise((resolve, reject) => {
    url.on('value', function(snapshot) {
      if (snapshot.val() != null) {
        const value = []
        Object.keys(snapshot.val()).map(key => {
          value.push({
            id: key,
            data: snapshot.val()[key]
          })
        })
        dispatch({type: 'CHANGE_DESTINATIONS', value: value})
        resolve(value)
      }
    })
  })
}

export const updateNoteDataAPI = (data) => (dispatch) => {
  const url = database.ref('notes/' + data.userId + '/' + data.noteId)
  return new Promise((resolve, reject) => {
    url.set({
      title: data.title,
      content: data.content,
      date: data.date
    }, (err) => {
      if( err ) {
        reject(false)
      } else {
        resolve(true)
      }
    })
  })
}

export const deleteNoteDataAPI = (data) => (dispatch) => {
  const url = database.ref('notes/' + data.userId + '/' + data.noteId)
  return new Promise((resolve, reject) => {
    url.remove()
  })
}

// Storage
export const addDestinationImage = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const url = storage.ref('destinations/'+data.name).put(data);
    url.on(
      "state_changed",
      snapshot => {},
      error => {
        reject(false)
      },
      () => {
        storage.ref('destinations').child(data.name).getDownloadURL().then(
          url => {
            resolve(url)
          }
        )
      }
    )
  })
}
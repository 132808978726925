import React from 'react';
import { Carousel } from 'react-bootstrap'
import './index.css';
import Banner from '../Banner'

const SliderTourImages = ({tour}) => {
  return (
    <Carousel>
      <Carousel.Item>
        <Banner img_url={tour.image_url_1}/>
      </Carousel.Item>
      <Carousel.Item>
        <Banner img_url={tour.image_url_2}/>
      </Carousel.Item>
      <Carousel.Item>
        <Banner img_url={tour.image_url_3}/>
      </Carousel.Item>
      <Carousel.Item>
        <Banner img_url={tour.image_url_4}/>
      </Carousel.Item>
      <Carousel.Item>
        <Banner img_url={tour.image_url_5}/>
      </Carousel.Item>
    </Carousel>
  )
}

export default SliderTourImages;
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { getActiveDestinationsAPI } from '../../../config/redux/action/destination';
import { ENUM_STATE } from '../../../utils/enums';
import DestinationCart from '../../organisms/DestinationCart';
import bg from '../../../assets/img/container/bg.webp'

class Destination extends Component {
  componentDidMount() {
    const { getDestinations } = this.props;
    getDestinations()
  }
  
  render() {
    const { destinationList } = this.props;
    return(  
      <div>
        <div className="relative w-full h-96">
          <img className="absolute inset-0 h-full w-full object-cover" src={bg} alt="background"/>
          <div className="absolute inset-0 h-full bg-gray-900 bg-opacity-30"></div>
          <div className="flex flex-col h-full items-center justify-end pb-10 relative">
            <h1 className="text-4xl text-gray-100 font-semibold">Jelajah Holiday</h1>
            <h1 className="text-xl text-gray-100">Creating Your Memorable Memories</h1>
          </div>
        </div>
        <div className="w-full mb-5 mt-10">
          <h1 className="text-center text-gray-900 text-4xl">Find Your Destination</h1>
        </div>
        <div className="flex flex-wrap justify-center max-w-screen-2xl m-auto">
          {
            destinationList.length > 0 ? (
              <Fragment>
                {
                  destinationList.map(destination => {
                    return (
                      <div>
                        {
                          destination.data.state === ENUM_STATE.ACTIVE ? (
                            <div className="flex flex-col m-5" key={destination.id}>
                              <DestinationCart destination={destination} key={destination.id}/>
                            </div>
                          ) : null
                        }
                      </div>
                    )
                  })
                }
              </Fragment>
            ) : null
          }
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  destinationList  : state.destinations
})

const reduxDispatch = (dispatch) => ({
  getDestinations     : () => dispatch(getActiveDestinationsAPI())
})

export default connect(reduxState, reduxDispatch)(Destination);
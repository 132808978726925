const initialState = {
  popup: false,
  isLogin: false,
  isLoading: false,
  user: {},
  notes: [],
  destinations: [],
  showAlert: { show: false },
  showLoginModal: false,
  banners: [],
  tours: [],
  tour: {},
}

const reducer = (state=initialState, action) => {
  if(action.type === 'CHANGE_POPUP') {
    return {
      ...state,
      popup: action.value
    }
  }
  if(action.type === 'CHANGE_ISLOGIN') {
    return {
      ...state,
      isLogin: action.value
    }
  }
  if(action.type === 'CHANGE_USER') {
    return {
      ...state,
      user: action.value
    }
  }
  if(action.type === 'CHANGE_ISLOADING') {
    return {
      ...state,
      isLoading: action.value
    }
  }
  if(action.type === 'CHANGE_NOTES') {
    return {
      ...state,
      notes: action.value
    }
  }
  if(action.type === 'CHANGE_DESTINATIONS') {
    return {
      ...state,
      destinations: action.value
    }
  }
  if(action.type === 'CHANGE_TOURS') {
    return {
      ...state,
      tours: action.value
    }
  }
  if(action.type === 'CHANGE_TOUR') {
    return {
      ...state,
      tour: action.value
    }
  }
  if(action.type === 'CHANGE_BANNERS') {
    return {
      ...state,
      banners: action.value
    }
  }
  if(action.type === 'CHANGE_SHOWALERT') {
    return {
      ...state,
      showAlert: action.value
    }
  }
  if(action.type === 'CHANGE_SHOWLOGINMODAL') {
    return {
      ...state,
      showLoginModal: action.value
    }
  }
  return state;
}

export default reducer;
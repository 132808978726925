import React, { useState } from 'react';
import { AiOutlineInstagram } from "react-icons/ai"
import { IconContext } from "react-icons";
import Input from '../../../components/atoms/Input';
import Button from '../../../components/atoms/Button';
import { subscribeEmail } from '../../../config/redux/action/newsletter';

const Footer = () => {
  const [email,setEmail] = useState();
  const [message, setMessage] = useState();

  return (
    <div>
      <div className="w-full h-1 bg-yellow-600 bg-opacity-20"></div>
      
      <div className="bg-blue-500 text-gray-100 pt-10">
        <div className="md:flex w-11/12 md:w-9/12 m-auto justify-between">
          <div className="flex flex-col md:w-4/12">
            <div className="text-4xl font-bold">
              Jelajah Holiday
            </div>
            <div className="mt-5">
              <div className="md:text-xl font-medium">PT Jelajah Arsa Gemilang</div>
              <div className="mt-1 text-gray-100 text-opacity-70">Jalan Raya Cipayung No. 3, Jakarta Timur, DKI Jakarta 13840</div>
            </div>
            <div className="mt-5 mb-2">
              <IconContext.Provider value={{ color: "white", size: "2em" }}>
                <div className="flex">
                  <a href='https://www.instagram.com/jelajahholiday/' className='flex'>
                    <AiOutlineInstagram/>
                    <p className='text-lg'>@jelajahholiday</p>
                  </a>
                </div>
              </IconContext.Provider>
            </div>
          </div>
          <div>
            <Input 
              label="Get Tour Update From Us!" 
              placeholder="myemail@domain.com"
              id="subscribeEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value) }
              />
            {
              message ?
                message.type === 'fail' ?
                  <p className='text-red-900'>{message.copy}</p>
                  :
                  <p className='text-green-300'>Subscribed successfully</p>
               : 
              null
            }
            <div className='pb-3 mt-3'>
              <Button 
                title="Subscribe!"
                color="yellow"
                onClick={
                  async () => {
                    const result = await subscribeEmail(email)
                    if(!result){
                      setMessage({
                        copy: 'Fail to subsribe, please input correct email',
                        type: 'fail'
                      })
                    }else{
                      setMessage({
                        copy: 'Your email has been registered',
                        type: 'success'
                      })
                    }
                    setEmail("")
                  }
                }
                />
              </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-blue-500 pt-2">
        <div className="pt-1 pb-1 text-center text-gray-100 border-t">© 2021 Jelajah Holiday. All rights reserved.</div>
      </div>
    </div>
  )
}

export default Footer;
import React from 'react';

const Tnc = ({content}) => {
  return (
    <div>
      <div className="text-sm md:text-lg" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

export default Tnc;
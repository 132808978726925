import axios from "axios";

export const getPaymentList = async () => {
  try{
    const resp = await axios.get(`${process.env.REACT_APP_BE_HOST}/payments`)
    return resp.data
  } catch(error) {
    console.log('ERROR', error.message)
    return false
  }
}
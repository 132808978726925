import React, { Component } from 'react';
import Button from '../../../components/atoms/Button';
import { connect } from 'react-redux';
import { toggleShowLoginModal } from '../../../config/redux/action'
import Input from '../../../components/atoms/Input';
import { withRouter } from 'react-router-dom';
import { loginUserAPI } from '../../../config/redux/action'

class LoginModal extends Component{
  state = {
    email: '',
    password: '',
    loginMode: true,
    selectedDate: '',
    confirmPassword: '',
    username: '',
    phoneNumber: ''
  }

  handleChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleLoginSubmit = async () => {
    const {history} = this.props
    const data = {
      email: this.state.email,
      password: this.state.password
    }
    const res = await this.props.loginAPI(data).catch(err => err)
    if (res) {
      localStorage.setItem('userData', JSON.stringify(res))
      this.setState({
        email: '',
        password: ''
      })
      history.push('/')
    } else {
      // TODO HANDLE FAIL HERE
    }
  }

  handleCloseLoginModal = () => {
    this.props.toggleShowLoginModal(false)
  }

  handleToggleRegisterLogin = () => {
    this.setState({
      loginMode: !(this.state.loginMode)
    })
  }

  handleRegisterSubmit = async () => {
    // const { history } = this.props
    // const data = {
    //   email: this.state.email,
    //   password: this.state.password
    // }
    // const res = await this.props.resgiterAPI(data).catch(err => err)
    // if (res) {
    //   this.setState({
    //     email: '',
    //     password: ''
    //   })
    //   history.push('/')
    // } else {
    //   // TODO HANDLE FAIL HERE
    // }
  }

  render() {
    const {showLoginModal} = this.props;
    const {loginMode } = this.state;

    return(
      <div>
        {
          showLoginModal ? (
            <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              {
                (loginMode) ? (
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="h-14 pt-3 bg-blue-500 rounded-t-md text-center">
                      <span className="text-2xl font-light text-gray-100">Log in to your account</span>
                    </div>
                    <div className="relative py-3 md:w-96 sm:max-w-xl mx-auto text-center">
                      <div className="py-6 px-8 text-left">
                        <Input id="email" label="Email" onChange={this.handleChangeText} type="text" placeholder="Email" value={this.props.email}/>
                        <Input id="password" label="Password" onChange={this.handleChangeText} type="password" placeholder="Password" value={this.props.password}/>
                        <div className="flex justify-between items-baseline">
                          <Button onClick={this.handleLoginSubmit} title="Log in"/>
                          {/* <a className="text-sm hover:underline">Forgot password?</a> */}
                        </div>
                      </div>
                      <div className="text-center py-6">
                        <span className="text-xl font-light">Don't have an account?</span>
                        <br className="hidden"/>
                        <span className="text-xl font-light text-blue-500 hover:text-blue-400 block hover:underline" onClick={this.handleToggleRegisterLogin}>Sign Up</span>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 mb-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <Button onClick={this.handleCloseLoginModal} title="Cancel"/>
                    </div>
                  </div>
                ) 
                : 
                (
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="h-14 pt-3 bg-blue-500 rounded-t-md text-center">
                      <span className="text-2xl font-light text-gray-100">Create your account</span>
                    </div>
                    <div className="relative py-3 md:w-96 sm:max-w-xl mx-auto text-center">
                      <div className="py-6 px-8 text-left">
                        <Input id="email" label="Email" onChange={this.handleChangeText} type="text" placeholder="Email" value={this.props.email}/>
                        <Input id="password" label="Password" onChange={this.handleChangeText} type="password" placeholder="Password" value={this.props.password}/>
                        <Input id="confirmPassword" label="Konfirmasi Password" onChange={this.handleChangeText} type="password" placeholder="Password" value={this.props.confirmPassword}/>
                        <Input id="username" label="Nama Lengkap" onChange={this.handleChangeText} type="text" placeholder="Nama Lengkap" value={this.props.username}/>
                        <Input id="phoneNumber" label="No HP" onChange={this.handleChangeText} type="text" placeholder="No Hp : 08123..." value={this.props.username}/>
                        <Input id="selectedDate" type='date' label='Tanggal Lahir' onChange={this.handleChangeText}/>
                        <div className="flex justify-between items-baseline">
                          <Button onClick={this.handleRegisterSubmit} title="Sign Up" loading={this.props.isLoading}/>
                        </div>
                      </div>
                      <div className="text-center py-6">
                        <span className="text-xl font-light">Already have an account?</span>
                        <br className="hidden"/>
                        <span className="text-xl font-light text-blue-500 hover:text-blue-400 block hover:underline" onClick={this.handleToggleRegisterLogin}>Log in</span>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 mb-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <Button onClick={this.handleCloseLoginModal} title="Cancel"/>
                    </div>
                  </div>
                )
              }
              </div>
              </div>
            </div>
          ) : null
        }
      </div>
    )
  }
}

const reduxState = (state) => ({
  showLoginModal  : state.showLoginModal,
  isLoading: state.isLoading
})

const reduxDispatch = (dispatch) => ({
  toggleShowLoginModal :  (data) => dispatch(toggleShowLoginModal(data)),
  loginAPI: (data) => dispatch(loginUserAPI(data))
})

export default withRouter(connect(reduxState, reduxDispatch)(LoginModal));
import React, { Component } from 'react';
import { getActiveBannersAPI } from '../../../config/redux/action/banner';
import { connect } from 'react-redux'
import { Carousel } from 'react-bootstrap'
import './index.css';
import Banner from '../Banner'

class SliderBanner extends Component {
  state = {
    banners: []
  }

  componentDidMount() {
    const { getBanners } = this.props;
    getBanners();
  }

  render() {
    const { bannerList } = this.props;
    return (
      <div>
        <Carousel>
          {
            bannerList.map(banner => {
              return(
                <Carousel.Item key={banner.id}>
                  <Banner 
                    img_url={banner.data.img_url}
                    redirect_url={"https://" + banner.data.redirect_url}
                  />
                  <Carousel.Caption>
                    <h3>{banner.data.title}</h3>
                    <p>{banner.data.description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })
          }
        </Carousel>
      </div>
    )
  }
}

const reduxState = (state) => ({
  bannerList  : state.banners
})

const reduxDispatch = (dispatch) => ({
  getBanners        : ()  => dispatch(getActiveBannersAPI())
})

export default connect(reduxState, reduxDispatch)(SliderBanner);
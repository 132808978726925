import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getActiveToursDataAPI, getToursDataByLocationAPI } from '../../../config/redux/action/tour';
import TourCart from '../../organisms/TourCart';
import queryString from 'query-string';
import { IoLocationOutline } from "react-icons/io5"
import { IconContext } from "react-icons";


class OurTour extends Component {
  componentDidMount() {
    const { retrieveTours, retrieveLocation } = this
    retrieveTours();
    retrieveLocation();
    setTimeout(() => {
        if(this.props.tourList.length === 0){
            this.setState({
                loading_text: "TIDAK ADA TOUR TERSEDIA"
            })
        }
    }, 2000)
  }

  state = {
      loading_text: 'LOADING...',
      location_name: null
  }

  retrieveLocation = () => {
      const {location} = this.props
      const values = queryString.parse(location.search)
      if(values.name !== undefined){
          this.setState({
              location_name: values.name
          })
      }
  }

  retrieveTours = () => {
    const { getTours, getToursDataByLocation, location } = this.props
    const values = queryString.parse(location.search)
    if(values.location === undefined){
        getTours();
    } else {
        getToursDataByLocation(values.location);
    }
  }
  
  render() {
    const { tourList } = this.props;
    const { loading_text, location_name } = this.state;
    return (
        <div className="w-full min-h-screen">
            <h1 className="text-center text-4xl mt-5 text-yellow-600">Tour Package</h1>
            {
                location_name === null ? null : (
                    <div className="flex justify-center m-2">
                        <IconContext.Provider value={{ size: "1.5em", color: "gray" }}>
                        <div>
                            <IoLocationOutline/>
                        </div>
                        </IconContext.Provider>
                        <h3 className="text-gray-800">{location_name}</h3>
                    </div>
                )
            }
            {
                tourList.length > 0 ? (
                    <div className="mt-5 w-11/12 flex flex-wrap justify-center m-auto">
                    {
                        tourList.map(tour => {
                        return (
                            <TourCart key={tour.id}
                            id={tour.id}
                            tour={tour.data}
                            />
                        )
                        })
                    }
                    </div>
                ) :
                <h1 className="text-center mt-20">{loading_text}</h1>    
            }
        </div>
    )
  }
}

const reduxState = (state) => ({
    tourList    : state.tours
})

const reduxDispatch = (dispatch) => ({
    getTours    :   () => dispatch(getActiveToursDataAPI()),
    getToursDataByLocation : (location) => dispatch(getToursDataByLocationAPI(location))
})

export default connect(reduxState, reduxDispatch)(OurTour);
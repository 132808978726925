import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from '../../../../../components/atoms/Alert';
import Button from '../../../../../components/atoms/Button';
import { toggleAlert } from '../../../../../config/redux/action';
import { getToursDataAPI, toggleTourAPI } from '../../../../../config/redux/action/tour';
import { ENUM_STATE } from '../../../../../utils/enums';
import { Link } from 'react-router-dom';
import { VscEdit } from "react-icons/vsc";
import { ADMIN_USERS } from '../../../../../utils/admin_users';

class ExclusiveTourManage extends Component {
  componentDidMount() {
    const { getTours } = this.props
    getTours();
  }

  handleToggle = (tour) => {
    const { toggleTour } = this.props
    toggleTour(tour)
    this.handleSuccessAlert()
  }

  handleSuccessAlert = () => {
    const { toggleAlert } = this.props
    const alertData = {
      show: true,
      words: 'Data has been saved successfully',
      type: 'SUCCESS'
    }
    toggleAlert(alertData)
    setTimeout(function(){ toggleAlert({ show: false }) }, 2000);
  }

  validateUserAccess = () => {
    const { history } = this.props;
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData === null) {
      history.push('/');
    }
    else if (!ADMIN_USERS.includes(userData.email)) {
      history.push('/');
    }
  }

  render() {
    this.validateUserAccess()
    const { tourList, showAlert } = this.props
    const { handleToggle } = this

    return(
      <div className="min-h-screen">
        <Alert show={showAlert.show} words={showAlert.words} type={showAlert.type}/>
        <div className="w-full mb-5">
          <h1 className="text-center text-gray-900 text-4xl pt-5">Manage Tour</h1>
        </div>
        <div className="flex justify-center mt-10 mb-10">
          <table className="table-fixed border w-11/12 border-gray-800">
            <thead className="border border-gray-800">
              <tr>
                <th className="w-2/3 border border-gray-800">Title</th>
                <th className="w-1/5 border border-gray-800">Status</th>
                <th className="w-1/4">Action</th>
              </tr>
            </thead>
            
            <tbody>
              {
                tourList.map(tour => {
                  return(
                    <tr key={tour.id}>
                      <td className="border border-gray-800 p-2">
                        <Link to={"/exclusive/tour/edit/" + tour.id}>
                          <div className="flex text-blue-600 hover:text-blue-300">
                            <VscEdit/>
                            {tour.data.title}
                          </div>
                        </Link>
                      </td>
                      <td className="border border-gray-800 p-2">
                        {
                          tour.data.state === ENUM_STATE.ACTIVE ? (
                            <p className="w-24 m-auto text-center bg-blue-500 border rounded-lg text-gray-100 py-1">ACTIVE</p>
                          ) : (
                            <p className="w-24 m-auto text-center bg-red-700 border rounded-lg text-gray-100 py-1">INACTIVE</p>
                          )
                        }
                      </td>
                      <td className="border border-gray-800 p-2">
                        {
                          tour.data.state === ENUM_STATE.ACTIVE ? (
                            <div className="flex justify-center space-x-2">
                              <Button title="Deactivate!" model="inactive" onClick={() => handleToggle(tour)}/>
                              <Button title="Duplicate" color="yellow" onClick={() => this.props.history.push(`/exclusive/tour/duplicate/${tour.id}`)}/>
                            </div>
                          ) : (
                            <div className="flex justify-center">
                              <Button title="Activate!" onClick={() => handleToggle(tour)}/>
                            </div>
                          )
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  tourList  : state.tours,
  showAlert : state.showAlert
})

const reduxDispatch = (dispatch) => ({
  getTours  : () => dispatch(getToursDataAPI()),
  toggleTour : (data) => dispatch(toggleTourAPI(data)),
  toggleAlert : (data) => dispatch(toggleAlert(data))
})

export default connect(reduxState, reduxDispatch)(ExclusiveTourManage);
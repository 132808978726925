import React, { Component } from 'react';
import Itinerary from '../../organisms/Itinerary';
import PriceDetail from '../../organisms/PriceDetail';
import { getTourDataAPI } from '../../../config/redux/action/tour';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Tnc from '../../organisms/Tnc';
import { getDestinationDataAPI } from '../../../config/redux/action/destination';
import { IoLogoWhatsapp } from "react-icons/io"
import { IoLocationOutline } from "react-icons/io5"
import { IconContext } from "react-icons";
import SliderTourImages from '../../organisms/SliderTourImages';
import { ENUM_STATE, TRIP_COST_CATEGORY } from '../../../utils/enums';
import { getMapValue } from '../../../config/redux/action/key_val';
import Registration from '../../organisms/Registration';
import CheckoutModal from '../../organisms/CheckoutModal';
import Button from '../../../components/atoms/Button';
import CheckoutCart from '../../organisms/CheckoutCart';

class TourDetail extends Component {
  state = {
    active_tab: 'Detail Harga',
    title: "",
    Itinerary: "",
    tnc: "",
    include: "",
    exclude: "",
    duration_day: "",
    duration_night: "",
    scheduled: "",
    min_quota: "",
    prices: [],
    dp_minimal: "",
    dp_due_date: "",
    full_pay_due_date: "",
    tour_dates: [],
    destination_id: "",
    destination: {},
    cost_category: "",
    refund_option: "",
    customRefundOptions: [],
    paymentTermOptions: [],
    price_view_option: "",
    image_url_1: "",
    image_url_2: "",
    image_url_3: "",
    image_url_4: "",
    image_url_5: "",
    tourId: '',
    state: ENUM_STATE.ACTIVE,
    allowDirectOrder: false,
    checkoutMode: false
  }

  componentDidMount = this.componentDidMount.bind(this);

  componentDidMount() {
    const id = this.props.match.params.id
    this.setState({
      tourId: id
    })
    const { getTour, getDestination } = this.props;
    if (id.substring(0, 4) === "Tour") {
      this.props.getMapValue(id).then((val) => {
        getTour(val).then((res) => {
          this.setState({
            title: res.title,
            itinerary: res.itinerary,
            tnc: res.tnc,
            include: res.include,
            exclude: res.exclude,
            duration_day: res.duration_day,
            duration_night: res.duration_night,
            scheduled: res.scheduled,
            min_quota: res.min_quota,
            prices: res.prices,
            dp_minimal: res.dp_minimal,
            dp_due_date: res.dp_due_date,
            full_pay_due_date: res.full_pay_due_date,
            refund_option: res.refund_option,
            customRefundOptions: res.customRefundOptions,
            paymentTermOptions: res.paymentTermOptions,
            price_view_option: res.price_view_option,
            tour_dates: res.tour_dates,
            destination_id: res.location,
            cost_category: res.cost_category,
            image_url_1: res.image_url_1,
            image_url_2: res.image_url_2,
            image_url_3: res.image_url_3,
            image_url_4: res.image_url_4,
            image_url_5: res.image_url_5,
            state: res.state,
            allowDirectOrder: res.allowDirectOrder || false
          })
        }).then( () => {
          getDestination(this.state.destination_id).then((res) => {
            this.setState({
              destination: res
            })
          })
        })
      })
    } else {
      getTour(id).then((res) => {
        this.setState({
          title: res.title,
          itinerary: res.itinerary,
          tnc: res.tnc,
          include: res.include,
          exclude: res.exclude,
          duration_day: res.duration_day,
          duration_night: res.duration_night,
          scheduled: res.scheduled,
          min_quota: res.min_quota,
          prices: res.prices,
          dp_minimal: res.dp_minimal,
          dp_due_date: res.dp_due_date,
          full_pay_due_date: res.full_pay_due_date,
          refund_option: res.refund_option,
          customRefundOptions: res.customRefundOptions,
          paymentTermOptions: res.paymentTermOptions,
          price_view_option: res.price_view_option,
          tour_dates: res.tour_dates,
          destination_id: res.location,
          cost_category: res.cost_category,
          image_url_1: res.image_url_1,
          image_url_2: res.image_url_2,
          image_url_3: res.image_url_3,
          image_url_4: res.image_url_4,
          image_url_5: res.image_url_5,
          state: res.state,
          allowDirectOrder: res.allowDirectOrder || false
        })
      }).then( () => {
        getDestination(this.state.destination_id).then((res) => {
          this.setState({
            destination: res,
          })
        })
      })
    }
  }

  handleClick = (event)  => {
    const id = event.target.getAttribute('data-id');
    this.setState({ active_tab: id })
  }

  handleOrderClick = () => {
    const id = this.props.match.params.id
    const tour_url = "https://jelajahholiday.com/tour/" + id + "?name=" + this.state.title.replaceAll(' ','-')
    const redirect_url = "https://wa.me/6281387446595?text=Saya+mau+pesan+perjalanan+"+ this.state.title +"+di+"+ tour_url +"+apa+masih+tersedia?"
    window.open(redirect_url)
    // this.props.getMapValue("my-key").then( (res) => {
    //   console.log(res)
    // })
  }

  handleToggleOrderMode = () => {
    const currentValue = this.state.checkoutMode
    this.setState({
      checkoutMode: !currentValue
    })
  }
  
  render() {
    const active_tab_style = "p-2 px-4 bg-blue-500 font-medium text-gray-100 rounded-t-lg"
    const inactive_tab_style = "p-2 px-4 text-gray-500 hover:text-gray-900"
    const { active_tab, 
            title, 
            itinerary, 
            tnc, 
            include, 
            exclude,
            duration_day,
            duration_night,
            prices,
            tour_dates,
            destination,
            min_quota,
            cost_category,
            image_url_1,
            image_url_2,
            image_url_3,
            image_url_4,
            image_url_5,
            dp_minimal,
            dp_due_date,
            full_pay_due_date,
            refund_option,
            price_view_option,
            tourId,
            state,
            customRefundOptions,
            paymentTermOptions,
            checkoutMode,
            allowDirectOrder } = this.state

    if(state !== ENUM_STATE.ACTIVE){
      this.props.history.push('/');
    }
    
    let quota = "Tanpa Minimal Kuota"
    if (min_quota > 1){
      quota = "Minimal " + min_quota + " Orang"
    }

    let budget_category = ""
    const int_cost_category = parseInt(cost_category,10) 
    if (int_cost_category === TRIP_COST_CATEGORY.BACKPACKER_TRIP){
      budget_category = "Paket Backpacker"
    } else if (int_cost_category === TRIP_COST_CATEGORY.BUDGET_TRIP){
      budget_category = "Paket Budget"
    } else if (int_cost_category === TRIP_COST_CATEGORY.REGULAR_TRIP){
      budget_category = "Paket Regular"
    } else {
      budget_category = "Paket Premium"
    }

    const sp = new URLSearchParams(this.props.location.search)
    const showModal = sp.get("submodal") === "true" ? true : false
    return(
      <div className="w-full mb-10">
        {/* <CTAModal isShown={showModal} tourId={tourId} destination={destination.name}/> */}
        {
          checkoutMode ? (
            <CheckoutModal 
              tourId={tourId}
              tourName={title}
              minQuota={min_quota}
              prices={prices}
              priceViewOption={price_view_option}
              toggleFunc={this.handleToggleOrderMode}/>
              ) : null
        }
        <div className="sm:hidden">
          <SliderTourImages tour={this.state}/>
        </div>

        <div className="w-11/12 mt-2 md:w-9/12 m-auto md:mt-10">
          <div>
              <h1 className="text-2xl md:text-4xl font-medium text-yellow-600">{title}</h1>
              {
                parseInt(duration_day) + parseInt(duration_night) === 1 ? (
                  <h3 className="text-xs md:text-sm text-gray-700">One Day Trip, {quota}, {budget_category}</h3>
                ) : (
                <h3 className="text-xs md:text-sm text-gray-700">{duration_day} Hari {duration_night} Malam, {quota}, {budget_category}</h3>
              )
              }
          </div>

          <div className="hidden sm:flex mt-2">
              <div className="w-1/2 mr-1">
                  <img className="h-96 object-cover w-full rounded-l-xl" src={image_url_1} alt='Destination'/>
              </div>

              <div className="w-1/4 flex flex-col mb-3 mr-1">
                  <img className="h-48 object-cover pb-0.5" src={image_url_2} alt='Destination'/>
                  <img className="h-48 object-cover pt-0.5" src={image_url_3} alt='Destination'/>
              </div>

              <div className="w-1/4 flex flex-col mb-3">
                  <img className="h-48 object-cover pb-0.5 rounded-tr-xl" src={image_url_4} alt='Destination'/>
                  <img className="h-48 object-cover pt-0.5 rounded-br-xl" src={image_url_5} alt='Destination'/>
              </div>
          </div>

          <div className="hidden sm:flex">
            <IconContext.Provider value={{ size: "1.5em", color: "gray" }}>
              <div>
                <IoLocationOutline/>
              </div>
            </IconContext.Provider>
            <h3 className="text-gray-800">{destination.name}</h3>
          </div>
        </div>

        <div className="sm:hidden mt-3 text-xl w-7/12 m-auto">
          {
            allowDirectOrder ? (
              <div className='flex justify-center'>
                <Button 
                  title='Order Now!' 
                  onClick={this.handleToggleOrderMode}
                  />
              </div>
            ) : (
              <div className="flex justify-center border rounded-lg pt-1 pb-1 bg-yellow-400 hover:bg-yellow-300 cursor-pointer"
                onClick={this.handleOrderClick}>
                <IconContext.Provider value={{ size: "1.5em", color: "green" }}>
                  <div>
                    <IoLogoWhatsapp/>
                  </div>
                </IconContext.Provider>
                <h2 className="text-lg pl-2 text-gray-900">Pesan Sekarang!</h2>
              </div>
            )
          }
          
        </div>

        <div>
          <div className="w-11/12 md:w-9/12 m-auto">
            <div className="flex">
              <div className="w-full mt-5 md:text-lg md:mr-5 bg-white md:mt-10">
                <div className="flex md:text-lg w-full mr-5 cursor-pointer" onClick={this.handleClick}>
                  <h2 data-id="Detail Harga" className={active_tab === "Detail Harga" ? active_tab_style : inactive_tab_style}>Detail</h2>
                  <h2 data-id="Itinerary" className={active_tab === "Itinerary" ? active_tab_style : inactive_tab_style}>Itinerary</h2>
                  <h2 data-id="Tnc" className={active_tab === "Tnc" ? active_tab_style : inactive_tab_style}>T&C</h2>
                  {
                    allowDirectOrder ? null : (
                      <h2 data-id="Pendaftaran" className={active_tab === "Pendaftaran" ? active_tab_style : inactive_tab_style}>Registration</h2>
                    )
                  }
                </div>
                <div className="h-1 bg-blue-500"></div>
                <div className="border-t-0 border-gray-300 rounded-lg shadow py-1">
                  <div className="m-5 text-gray-800">
                    { active_tab === "Detail Harga" ? <PriceDetail include={include} exclude={exclude} prices={prices} tour_dates={tour_dates} price_view_option={price_view_option}/>: null }
                    { active_tab === "Itinerary" ? <Itinerary content={itinerary}/> : null }
                    { active_tab === "Tnc" ? <Tnc content={tnc}/> : null }
                    { active_tab === "Pendaftaran" ? <Registration dp_minimal={dp_minimal} dp_due_date={dp_due_date} full_pay_due_date={full_pay_due_date} refund_option={refund_option} customRefundOptions={customRefundOptions} paymentTermOptions={paymentTermOptions}/> : null }
                  </div>
                </div>
              </div>
              <div className="hidden sm:block mt-10 text-xl w-1/3">
                {
                  allowDirectOrder ? (
                    <CheckoutCart
                    tourId={tourId}
                    tourName={title}
                    minQuota={min_quota}
                    prices={prices}
                    priceViewOption={price_view_option}
                    />
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
        <div className="fixed bottom-10 right-10 border rounded-full w-20 h-20 p-4 bg-yellow-300 hover:bg-yellow-200 cursor-pointer"
          onClick={this.handleOrderClick}>
          <IconContext.Provider value={{ size: "3em", color: "green" }}>
            <div>
              <IoLogoWhatsapp/>
            </div>
          </IconContext.Provider>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  tourList  : state.tours
})

const reduxDispatch = (dispatch) => ({
  getTour     : (data) => dispatch( getTourDataAPI(data) ),
  getDestination :  (data)  => dispatch( getDestinationDataAPI(data)),
  getMapValue : (data) => dispatch( getMapValue(data) )
})

export default withRouter(connect(reduxState, reduxDispatch)(TourDetail));
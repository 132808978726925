import React from 'react';
import { BsCalendar, BsPeople, BsWallet } from "react-icons/bs"
import { Link } from 'react-router-dom';
import { TRIP_COST_CATEGORY } from '../../../utils/enums';


const TourCart = ({id, tour}) => { 
  
  var max_price = 0
  var min_price = Number.MAX_SAFE_INTEGER
  tour.prices.forEach(price => {
    var temp_price = parseInt(price.price.replaceAll('.',''))
    if(temp_price > max_price) {
      max_price = temp_price
    }
    if(temp_price < min_price) {
      min_price = temp_price
    }
  });
  min_price = min_price.toLocaleString("en-GB", {style: "currency", currency: "IDR", minimumFractionDigits: 0}).replaceAll(',','.').replaceAll('IDR','')
  max_price = max_price.toLocaleString("en-GB", {style: "currency", currency: "IDR", minimumFractionDigits: 0}).replaceAll(',','.').replaceAll('IDR','')
  var trimmed_title = tour.title
  if(trimmed_title.length > 35){
    trimmed_title = trimmed_title.substring(0,32) + '...'
  }

  var quota = "Tanpa Minimal Kuota"
  if (tour.min_quota > 1){
    quota = "Minimal " + tour.min_quota + " Orang"
  }

  var budget_category = ""
  const cost_category = parseInt(tour.cost_category,10) 
  if(cost_category === TRIP_COST_CATEGORY.BACKPACKER_TRIP){
    budget_category = "Paket Backpacker"
  }else if (cost_category === TRIP_COST_CATEGORY.BUDGET_TRIP){
    budget_category = "Paket Budget"
  } else if (cost_category === TRIP_COST_CATEGORY.REGULAR_TRIP){
    budget_category = "Paket Regular"
  } else {
    budget_category = "Paket Premium"
  }

  return (
    <div className="border rounded-lg shadow overflow-hidden w-64 h-80 md:w-80 md:h-96 cursor-pointer m-4 mb-2 md:mb-4 bg-gray-50 hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <Link to={"/tour/" + id}>
        <div className="relative h-40 md:h-52">
          <img className="absolute inset-0 h-40 md:h-52 w-full object-cover" src={tour.image_url_1} alt='Destination'/>
          {
            max_price === min_price ? (
              <h2 className="border absolute bottom-0 text-sm md:text-lg text-gray-800 font-semibold bg-yellow-400 px-2">Rp{max_price} /pax</h2>
            ) : (
              <h2 className="border absolute bottom-0 text-sm md:text-lg text-gray-800 font-semibold bg-yellow-400 px-2">Start From Rp{min_price}/Pax</h2>
            )
          }
        </div>
        <h1 className="text-lg md:text-xl text-blue-800 font-semibold ml-3 mb-3 mr-3 mt-2 hover:text-blue-400">{trimmed_title}</h1>
        <div id="hihi" className="flex flex-col justify-end">
          <div className="flex ml-3 mb-1">
            <BsCalendar/>
            {
              parseInt(tour.duration_day) + parseInt(tour.duration_night) === 1 ? (
                <h3 className="text-sm text-gray-800 ml-2">One Day Trip</h3>
              ) : (
                <h3 className="text-sm text-gray-800 ml-2">{tour.duration_day} Hari {tour.duration_night} Malam</h3>
              )
            }
            
          </div>
          <div className="flex ml-3 mb-1">
            <BsPeople/>
            <h3 className="text-sm text-gray-800 ml-2">{quota}</h3>
          </div>
          <div className="flex ml-3 mb-3">
            <BsWallet/>
            <h3 className="text-sm text-gray-800 ml-2">{budget_category}</h3>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default TourCart;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editTourAPI, getTourDataByIdAPI } from '../../../../../config/redux/action/tour';
import { ADMIN_USERS } from '../../../../../utils/admin_users';
import Input from '../../../../../components/atoms/Input';
import { getAllDestinationsAPI } from '../../../../../config/redux/action/destination';
import { PRICE_VIEW, REFUND_OPTION, TRIP_TYPE } from '../../../../../utils/enums'
import { TRIP_COST_CATEGORY } from '../../../../../utils/enums'
import Button from '../../../../../components/atoms/Button';
import { toggleAlert } from '../../../../../config/redux/action';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { addTourImage } from '../../../../../config/redux/action/tour';


class ExclusiveTourEdit extends Component {
  state = {
    title: "",
    itinerary: EditorState.createEmpty(),
    tnc: EditorState.createEmpty(),
    include: EditorState.createEmpty(),
    exclude: EditorState.createEmpty(),
    duration_day: "",
    duration_night: "",
    cost_category: "",
    min_quota: "",
    state: "",
    prices: [],
    tour_dates: [],
    price_options: 0,
    dp_minimal: "",
    dp_due_date: "",
    full_pay_due_date: "",
    refund_option: "",
    price_view_option: "",
    location: "",
    trip_type: "",
    image_url_1: "",
    image_url_2: "",
    image_url_3: "",
    image_url_4: "",
    image_url_5: "",
    image_url_1_change: "",
    image_url_2_change: "",
    image_url_3_change: "",
    image_url_4_change: "",
    image_url_5_change: "",
    image_1: "",
    image_2: "",
    image_3: "",
    image_4: "",
    image_5: "",
    onProgressSubmit: false,
    customRefundOptions: [],
    customRefunOptionCount: 0,
    paymentTermOptions: [],
    paymentTermOptionCount: 0,
    allowDirectOrder: false
  }

  componentDidMount() {
    const { reconvertHtmlStyle } = this;
    const { getDestinations } = this.props;
    const id = this.props.match.params.id
    this.props.getTourAPI(id).then((res) => {
      this.setState({
        title: res.title,
        itinerary:  EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML( reconvertHtmlStyle(res.itinerary) )
          )
        ),
        tnc: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML( reconvertHtmlStyle(res.tnc) )
          )
        ),
        include: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML( reconvertHtmlStyle(res.include) )
          )
        ),
        exclude: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML( reconvertHtmlStyle(res.exclude) )
          )
        ),
        duration_day: res.duration_day,
        duration_night: res.duration_night,
        cost_category: res.cost_category,
        min_quota: res.min_quota,
        state: res.state,
        prices: res.prices,
        tour_dates: res.tour_dates,
        price_options: res.prices.length,
        location: res.location,
        dp_minimal: res.dp_minimal,
        dp_due_date: res.dp_due_date,
        full_pay_due_date: res.full_pay_due_date,
        refund_option: res.refund_option,
        customRefundOptions: res.customRefundOptions || [],
        customRefunOptionCount: res?.customRefundOptions?.length || 0,
        price_view_option: res.price_view_option,
        trip_type: res.trip_type,
        image_url_1: res.image_url_1,
        image_url_2: res.image_url_2,
        image_url_3: res.image_url_3,
        image_url_4: res.image_url_4,
        image_url_5: res.image_url_5,
        paymentTermOptions: res.paymentTermOptions || [],
        paymentTermOptionCount: res?.paymentTermOptions?.length || 0,
        allowDirectOrder: res?.allowDirectOrder || false
      })
    })
    getDestinations();
  }

  handleToggleAllowDirectOrder = () => {
    this.setState({
      allowDirectOrder: !this.state.allowDirectOrder
    })
  } 

  handleChangeText = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    })
  }

  handleChangeTourDates = (e) => {
    this.setState({
      [e.target.id]: e.target.value.split(",")
    })
  }

  handleSelectLocation = (e) => {
    this.setState({
        location: e.target.value
    })
  }

  handleSelectTripType = (e) => {
    this.setState({
        trip_type: e.target.value
    })
  }

  reconvertHtmlStyle = (str) => {
    const converted_str = str.replaceAll( "<ul class=\"ml-8 list-outside list-disc\">", "<ul>")
                            .replaceAll( "<ol class=\"ml-8 list-outside list-decimal\">", "<ol>")
                            .replaceAll("<h2 class=\"mt-5 mb-2\">", "<h2>")
    return converted_str
  }

  convertHtmlStyle = (str) => {
    return draftToHtml(convertToRaw(str.getCurrentContent() ))
        .replaceAll("<ul>", "<ul class=\"ml-8 list-outside list-disc\">")
        .replaceAll("<ol>", "<ol class=\"ml-8 list-outside list-decimal\">")
        .replaceAll("<h2>", "<h2 class=\"mt-5 ml-2\">")
  }

  validateUserAccess = () => {
    const { history } = this.props;
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData === null) {
      history.push('/');
    }
    else if (!ADMIN_USERS.includes(userData.email)) {
      history.push('/');
    }
  }

  handleSelectCostCategory = (e) => {
    this.setState({
        cost_category: e.target.value
    })
  }

  handleSubmit = async () => {
    const { convertHtmlStyle } = this;
    const { uploadImage } = this.props;
    this.setState({
      onProgressSubmit: true
    })
    const data = {
      id: this.props.match.params.id,
      data: {
        title: this.state.title,
        itinerary: convertHtmlStyle(this.state.itinerary),
        tnc: convertHtmlStyle(this.state.tnc),
        include: convertHtmlStyle(this.state.include),
        exclude: convertHtmlStyle(this.state.exclude),
        duration_day: this.state.duration_day,
        duration_night: this.state.duration_night,
        cost_category: this.state.cost_category,
        min_quota: this.state.min_quota,
        state: this.state.state,
        prices: this.state.prices,
        dp_minimal: this.state.dp_minimal,
        dp_due_date: this.state.dp_due_date,
        full_pay_due_date: this.state.full_pay_due_date,
        refund_option: this.state.refund_option,
        customRefundOptions: this.state.customRefundOptions,
        price_view_option: this.state.price_view_option,
        tour_dates: this.state.tour_dates,
        location: this.state.location,
        trip_type: this.state.trip_type,
        image_url_1: this.state.image_url_1,
        image_url_2: this.state.image_url_2,
        image_url_3: this.state.image_url_3,
        image_url_4: this.state.image_url_4,
        image_url_5: this.state.image_url_5,
        paymentTermOptions: this.state.paymentTermOptions,
        allowDirectOrder: this.state.allowDirectOrder
      }
    }

    if(this.state.image_url_1_change){
      const res = await uploadImage(this.state.image_1)
      data.data.image_url_1 = res
    }

    if(this.state.image_url_2_change){
      const res = await uploadImage(this.state.image_2)
      data.data.image_url_2 = res
    }

    if(this.state.image_url_3_change){
      const res = await uploadImage(this.state.image_3)
      data.data.image_url_3 = res
    }

    if(this.state.image_url_4_change){
      const res = await uploadImage(this.state.image_4)
      data.data.image_url_4 = res
    }

    if(this.state.image_url_5_change){
      const res = await uploadImage(this.state.image_5)
      data.data.image_url_5 = res
    }

    this.props.editTourAPI(data)
    window.location.href=("/tour/" + this.props.match.params.id )
  }

  handleItinerary = (itinerary) => {
    this.setState({
        itinerary
    })
  }

  handleTnc = (tnc) => {
    this.setState({
        tnc
    })
  }

  handleInclude = (include) => {
      this.setState({
          include
      })
  }

  handleExclude = (exclude) => {
      this.setState({
          exclude
      })
  }

  handleChangePriceOptions = (e) => {
    let splitter = e.target.id.split("_")
    let index = parseInt(splitter[1])
    let obj_name = splitter[0]
    let curr_val = this.state.prices
    if (curr_val[index] === undefined ) {
        curr_val[index] = {
            price: "",
            minquota: "",
            origin: ""
        }
    }
    curr_val[index][obj_name] = e.target.value
    this.setState({
        prices: curr_val
    })
  }

  handleChangeCustomRefundOption = (e) => {
    let splitter = e.target.id.split("_")
    let index = parseInt(splitter[1])
    let obj_name = splitter[0]
    let curr_val = this.state.customRefundOptions
    if(curr_val[index] === undefined){
      curr_val[index] = {
        timeframe: "",
        downpayment: "",
        fullpayment: ""
      }
    }
    curr_val[index][obj_name] = e.target.value
    this.setState({
      customRefundOptions: curr_val
    })
  }

  handleChangePaymentTermOption = (e) => {
    let splitter = e.target.id.split("_")
    let index = parseInt(splitter[1])
    let obj_name = splitter[0]
    let curr_val = this.state.paymentTermOptions
    if(curr_val[index] === undefined){
      curr_val[index] = {
        term: "",
        nominal: "",
        timeframe: ""
      }
    }
    curr_val[index][obj_name] = e.target.value
    this.setState({
      paymentTermOptions: curr_val
    })
  }

  handleIncreasePriceOption = () => {
    const price_options = this.state.price_options
    this.setState({
        price_options: (price_options+1)    
    })
  }

  handleIncreaseCustomRefundOption = () => {
    const customRefunOptionCount = this.state.customRefunOptionCount
    this.setState({
        customRefunOptionCount: (customRefunOptionCount+1)    
    })
  }

  handleIncreasePaymentTermOption = () => {
    const paymentTermOptionCount = this.state.paymentTermOptionCount
    this.setState({
        paymentTermOptionCount: (paymentTermOptionCount+1)    
    })
  }

  handleResetPriceOption = () => {
    this.setState({
        price_options: 0,
        prices: [] 
    })
  }

  handleResetCustomRefundOption = () => {
    this.setState({
        customRefunOptionCount: 0,
        customRefundOptions: [] 
    })
  }

  handleResetPaymentTermOption = () => {
    this.setState({
        paymentTermOptions: [],
        paymentTermOptionCount: 0 
    })
  }

  handleImageChange = (e) => {
    var id = ("image_" + e.target.id)
    var url = ("image_url_" + e.target.id + "_change")
    if(e.target.files[0]){
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2){
            this.setState({
                [id]: e.target.files[0],
                [url]: reader.result
            })
        }
      } 
      reader.readAsDataURL(e.target.files[0])
    }
  }

  cancelImageChange = (eId) => {
    var id = ("image_" + eId)
    var url = ("image_url_" + eId + "_change")
    this.setState({
      [id]: '',
      [url]: ''
    })
  }

  render() {
    this.validateUserAccess()
    const { destinationList } = this.props
    const {
      title,
      location,
      trip_type,
      cost_category,
      duration_day,
      duration_night,
      min_quota,
      itinerary,
      include,
      exclude,
      tnc,
      prices,
      price_options,
      image_url_1,
      image_url_2,
      image_url_3,
      image_url_4,
      image_url_5,
      image_url_1_change,
      image_url_2_change,
      image_url_3_change,
      image_url_4_change,
      image_url_5_change,
      tour_dates,
      refund_option,
      price_view_option,
      onProgressSubmit,
      customRefunOptionCount,
      customRefundOptions,
      paymentTermOptions,
      paymentTermOptionCount,
      allowDirectOrder
    } = this.state
    const {
      handleChangeText,
      handleSelectLocation,
      handleSelectTripType,
      handleSelectCostCategory,
      handleSubmit,
      handleItinerary,
      handleInclude,
      handleExclude,
      handleTnc,
      handleChangePriceOptions,
      handleIncreasePriceOption,
      handleResetPriceOption,
      handleImageChange,
      handleChangeTourDates,
      cancelImageChange,
      handleIncreaseCustomRefundOption,
      handleResetCustomRefundOption,
      handleChangeCustomRefundOption,
      handleIncreasePaymentTermOption,
      handleResetPaymentTermOption,
      handleChangePaymentTermOption,
      handleToggleAllowDirectOrder
    } = this

    let priceItems = []
    for(let i = 0; i < price_options; i++){
      priceItems.push(<div key={i}>
        <p className="pt-6 ml-2 mr-2"> Harga</p>
        <Input
            id ={"price_" + i}
            placeholder="200.000"
            onChange={handleChangePriceOptions}
            value={prices[i] ? prices[i].price : null}
        />
        <p className="pt-6 ml-2 mr-2"> Perorang untuk minimal pemesanan </p>
        <Input
            id ={"minquota_" + i}
            placeholder="5"
            onChange={handleChangePriceOptions}
            value={prices[i] ? prices[i].minquota : null}
        />
        <p className="pt-6 ml-2 mr-2"> Keberangkatan dari </p>
        <Input
            id ={"origin_" + i}
            placeholder="Jakarta"
            onChange={handleChangePriceOptions}
            value={prices[i] ? prices[i].origin : null}
        />
        <div className=" mt-2 h-1 bg-gray-400"></div>
      </div>);
    }

    let customRefundOptionList = []
    for(let i = 0; i < customRefunOptionCount; i++){
      customRefundOptionList.push(
        <div key={i}>
          <p className="pt-6 ml-2 mr-2">Waktu Sebelum Tour</p>
          <Input
            id ={`timeframe_${i}`}
            placeholder="> 10 Hari"
            onChange={handleChangeCustomRefundOption}
            value={customRefundOptions[i] ? customRefundOptions[i].timeframe : null}
          />

          <p className="pt-6 ml-2 mr-2">Refund Jika Telah Menyelesaikan Pembayaran Ke-1</p>
          <Input
            id ={`downpayment_${i}`}
            placeholder="No Refund"
            onChange={handleChangeCustomRefundOption}
            value={customRefundOptions[i] ? customRefundOptions[i].downpayment : null}
          />

          <p className="pt-6 ml-2 mr-2">Refund Jika Telah Menyelesaikan Pembayaran Ke-2</p>
          <Input
            id ={`fullpayment_${i}`}
            placeholder="75%"
            onChange={handleChangeCustomRefundOption}
            value={customRefundOptions[i] ? customRefundOptions[i].fullpayment : null}
          />
          <div className=" mt-2 h-1 bg-gray-400"></div>
        </div>
      )
    }

    let paymentTermList = []
    for(let i = 0; i < paymentTermOptionCount; i++){
      paymentTermList.push(
        <div key={i}>
          <p className="pt-6 ml-2 mr-2">Term Name</p>
          <Input
            id ={`term_${i}`}
            placeholder="Term 1 / Deposit / Down Payment / Full Payment"
            onChange={handleChangePaymentTermOption}
            value={paymentTermOptions[i] ? paymentTermOptions[i].term : null}
          />

          <p className="pt-6 ml-2 mr-2">Nominal</p>
          <Input
            id ={`nominal_${i}`}
            placeholder="Rp 500.000 / 10% / 100%"
            onChange={handleChangePaymentTermOption}
            value={paymentTermOptions[i] ? paymentTermOptions[i].nominal : null}
          />

          <p className="pt-6 ml-2 mr-2">Maksimum Waktu Pembayaran</p>
          <Input
            id ={`timeframe_${i}`}
            placeholder="H-7"
            onChange={handleChangePaymentTermOption}
            value={paymentTermOptions[i] ? paymentTermOptions[i].timeframe : null}
          />

          <div className=" mt-2 h-1 bg-gray-400"></div>
        </div>
      )
    }



    let processed_tour_dates = ""
    for(let i = 0; i < tour_dates.length; i++){
      if( i > 0) {
        processed_tour_dates = processed_tour_dates + "," + tour_dates[i]
      } else {
        processed_tour_dates = tour_dates[i]
      }
    }

    return(
      <div className="w-full bg-gray-50">
        <div className="w-9/12 m-auto">
          <Input 
            label="Title"
            id="title"
            placeholder="Paket Open Trip..."
            onChange={handleChangeText}
            value={title}
            />
          
          <label className="block font-semibold mt-2"/>Lokasi :
          <select className="ml-2 mt-2 border-2 rounded-sm" 
                  value={location} 
                  onChange={handleSelectLocation}>
              {
                destinationList.map(destination => {
                  return(
                      <option key={destination.id} value={destination.id}>{destination.data.name}</option>
                  )
                })
              }
          </select>

          <Input
            label="Tour Dates"
            id="tour_dates"
            placeholder="Date 1,Date 2, ..."
            onChange={handleChangeTourDates}
            value={processed_tour_dates}
            />

          <label className="block font-semibold mt-2"/>Tipe Trip :
          <select className="ml-2 mt-2 border-2 rounded-sm" 
                  value={trip_type} 
                  onChange={handleSelectTripType}
                  >
              <option value={TRIP_TYPE.OPEN_TRIP}>Open Trip</option>
              <option value={TRIP_TYPE.PRIVATE_TRIP}>Private Trip</option>
              <option value={TRIP_TYPE.FAMILY_TRIP}>Family Trip</option>
          </select>

          <label className="block font-semibold mt-2"/>Cost Category :
          <select className="ml-2 mt-2 border-2 rounded-sm" 
                  value={cost_category} 
                  onChange={handleSelectCostCategory}
                  >
              <option value={ TRIP_COST_CATEGORY.BACKPACKER_TRIP }>Backpacker Trip</option>
              <option value={ TRIP_COST_CATEGORY.BUDGET_TRIP }>Budget Trip</option>
              <option value={ TRIP_COST_CATEGORY.REGULAR_TRIP }>Regular Trip</option>
              <option value={ TRIP_COST_CATEGORY.PREMIUM_TRIP }>Premium Trip</option>
          </select>

          <div>
            <label className="block font-semibold mt-2"/>Durasi
            <div className="flex w-3/12">
                <Input 
                    id="duration_day"
                    placeholder="0"
                    onChange={handleChangeText}
                    value={duration_day}
                    />
                <p className="pt-6 ml-2 mr-2">Hari</p>
                <Input 
                    id="duration_night"
                    placeholder="0"
                    onChange={handleChangeText}
                    value={duration_night}
                    />
                <p className="pt-6 ml-2 mr-2">Malam</p>
            </div>
          </div>

          <div>
            <div className="flex w-3/12">
                <p className="pt-6 ml-2 mr-2">Minimal</p>
                <Input 
                    id="min_quota"
                    placeholder="0"
                    onChange={handleChangeText}
                    value={min_quota}
                    />
                <p className="pt-6 ml-2 mr-2">Orang</p>
            </div>
          </div>

          {/* IMG Change LOGIC */}
          <div className='flex'>
            <div className='m-1'>
              <img 
                className="border border-blue-400 w-96 h-40 mb-0 object-cover" 
                src={ image_url_1_change || image_url_1 } 
                alt='Destination'/>
              { image_url_1_change ? (
                <div className='mt-4'>
                  <Button 
                    title='Cancel'
                    onClick={() => cancelImageChange(1)}
                    color='yellow'/>
                </div>
              ) : (
                <Input 
                id='1'
                type='file' 
                label='Change Image' 
                model='button'
                onChange={handleImageChange}/>
              ) }
            </div>
            <div className='m-1'>
              <img 
                className="border border-blue-400 w-96 h-40 mb-0 object-cover" 
                src={ image_url_2_change || image_url_2 } 
                alt='Destination'/>
              { image_url_2_change ? (
                <div className='mt-4'>
                  <Button 
                    title='Cancel'
                    onClick={() => cancelImageChange(2)}
                    color='yellow'/>
                </div>
              ) : (
                <Input 
                id='2'
                type='file' 
                label='Change Image' 
                model='button'
                onChange={handleImageChange}/>
              ) }
            </div>
            <div className='m-1'>
              <img 
                className="border border-blue-400 w-96 h-40 mb-0 object-cover" 
                src={ image_url_3_change || image_url_3 } 
                alt='Destination'/>
              { image_url_3_change ? (
                <div className='mt-4'>
                <Button 
                  title='Cancel'
                  onClick={() => cancelImageChange(3)}
                  color='yellow'/></div>
              ) : (
                <Input 
                id='3'
                type='file' 
                label='Change Image' 
                model='button'
                onChange={handleImageChange}/>
              ) }
            </div>

            <div className='m-1'>
              <img 
                className="border border-blue-400 w-96 h-40 mb-0 object-cover" 
                src={ image_url_4_change || image_url_4 } 
                alt='Destination'/>
              { image_url_4_change ? (
                <div className='mt-4'><Button 
                  title='Cancel'
                  onClick={() => cancelImageChange(4)}
                  color='yellow'/></div>
              ) : (
                <Input 
                id='4'
                type='file' 
                label='Change Image' 
                model='button'
                onChange={handleImageChange}/>
              ) }
            </div>

            <div className='m-1'>
              <img 
                className="border border-blue-400 w-96 h-40 mb-0 object-cover" 
                src={ image_url_5_change || image_url_5 } 
                alt='Destination'/>
              { image_url_5_change ? (
                <div className='mt-4'><Button 
                  title='Cancel'
                  onClick={() => cancelImageChange(5)}
                  color='yellow'/></div>
              ) : (
                <Input 
                id='5'
                type='file' 
                label='Change Image' 
                model='button'
                onChange={handleImageChange}/>
              ) }
            </div>
          </div>

          <div>
              <label className="block font-semibold mt-2"/>Itinerary
              <div className="shadow rounded-md border-0 bg-white mt-1">
              <Editor
                  editorState={itinerary}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleItinerary}
              />
              </div>
          </div>

          <div>
              <label className="block font-semibold mt-2"/>Paket Termasuk
              <div className="shadow rounded-md border-0 bg-white mt-1">
              <Editor
                  editorState={include}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleInclude}
              />
              </div>
          </div>

          <div>
              <label className="block font-semibold mt-2"/>Paket Tidak Termasuk
              <div className="shadow rounded-md border-0 bg-white mt-1">
              <Editor
                  editorState={exclude}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleExclude}
              />
              </div>
          </div>

          <div>
              <label className="block font-semibold mt-2"/>Syarat & Ketentuan
              <div className="shadow rounded-md border-0 bg-white mt-1">
              <Editor
                  editorState={tnc}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleTnc}
              />
              </div>
          </div>

          <div className="border-2 mt-4 pl-4 pb-4">
              <div className="mr-4">{priceItems}</div>
              <div className='flex space-x-2 mt-4'>
                <Button title="Add Price Options" onClick={handleIncreasePriceOption}/>
                <Button title="Reset Price Options" onClick={
                            () => {
                        priceItems = []
                        handleResetPriceOption()
                    }
                }
                model='inactive'/>
              </div>
          </div>

          <div class="flex pt-6">
              <p className="ml-2 mr-5">Price View Option:</p>
              <Input
                id="price_view_option"
                type="radio"
                value={PRICE_VIEW.ORIGIN}
                checked={price_view_option === PRICE_VIEW.ORIGIN}
                onChange={handleChangeText}
                label="Differ by Origin"
              />
              <Input
                id="price_view_option"
                type="radio"
                value={PRICE_VIEW.PERSON}
                checked={price_view_option === PRICE_VIEW.PERSON}
                onChange={handleChangeText}
                label="Differ by Person Amount"
              />
              <Input
                id="price_view_option"
                type="radio"
                value={PRICE_VIEW.BOTH}
                checked={price_view_option === PRICE_VIEW.BOTH}
                onChange={handleChangeText}
                label="Differ by Both"
              />
          </div>
          
          {/* Start Payment Terms */}
          <div className="border-2 mt-4 pl-4 pb-4">
            <div className="mr-4">{paymentTermList}</div>
            <div className='flex space-x-2 mt-4'>
            <Button title="Add Payment Terms" onClick={handleIncreasePaymentTermOption}/>
            <Button title="Reset Payment Terms" onClick={
                        () => {
                    paymentTermList = []
                    handleResetPaymentTermOption()
                }
            }
            model='inactive'/></div>
          </div>
          {/* End Payment Terms */}


          <div class="flex pt-6">
              <p className="ml-2 mr-5">Refund Options:</p>
              <Input
                id="refund_option"
                type="radio"
                value={REFUND_OPTION.MAX_7}
                checked={refund_option === REFUND_OPTION.MAX_7}
                onChange={handleChangeText}
                label="7 Days Period"
              />
              <Input
                id="refund_option"
                type="radio"
                value={REFUND_OPTION.MAX_14}
                checked={refund_option === REFUND_OPTION.MAX_14}
                onChange={handleChangeText}
                label="14 Days Period"
              />
              <Input
                id="refund_option"
                type="radio"
                value={REFUND_OPTION.MAX_30}
                checked={refund_option === REFUND_OPTION.MAX_30}
                onChange={handleChangeText}
                label="30 Days Period"
              />
              <Input
                id="refund_option"
                type="radio"
                value={REFUND_OPTION.CUSTOM}
                checked={refund_option === REFUND_OPTION.CUSTOM}
                onChange={handleChangeText}
                label="Custom"
              />
          </div>

          { refund_option === REFUND_OPTION.CUSTOM ? (
            <div className="border-2 mt-4 pl-4 pb-4">
              <div className="mr-4">{customRefundOptionList}</div>
              <Button title="Add Refund Options" onClick={handleIncreaseCustomRefundOption}/>
              <Button title="Reset Refund Options" onClick={
                          () => {
                      customRefundOptionList = []
                      handleResetCustomRefundOption()
                  }
              }
              model='inactive'/>
            </div>
          ) : null }

          {/* AllowUserDirectOrder */}
          <div className='ml-2'>
              <Input 
                id='allowDirectOrder' 
                type='checkbox' 
                label='Allow User To Directly Order'
                checked={allowDirectOrder}
                onChange={handleToggleAllowDirectOrder}/>
          </div>
          
          <div className='mt-4 ml-2'>
          {
            onProgressSubmit ? (
                <Button loading="true" onClick={handleSubmit}/>
            ) : (
                <Button title="Submit" onClick={handleSubmit}/>
            )
          }
          </div>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  destinationList : state.destinations
})

const reduxDispatch = (dispatch) => ({
  getTourAPI  : (data) => dispatch(getTourDataByIdAPI(data)),
  getDestinations : () => dispatch(getAllDestinationsAPI()),
  editTourAPI : (data)  => dispatch(editTourAPI(data)),
  toggleAlert         : (data) => dispatch(toggleAlert(data)),
  uploadImage:   (data) => dispatch(addTourImage(data))
})

export default connect(reduxState, reduxDispatch)(ExclusiveTourEdit);
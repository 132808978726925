/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ADMIN_USERS } from '../../../utils/admin_users';
import LoginModal from '../LoginModal';
import { connect } from 'react-redux';
import { toggleShowLoginModal } from '../../../config/redux/action'
import { GiHamburgerMenu } from "react-icons/gi";
import { IconContext } from "react-icons";
import logo from "../../../assets/img/logo/logo-full.png"

class Navbar extends Component {
  state = {
    toggledProfile: false,
    hamburgerToggled: false
  }

  handleActiveToggleProfile = () => {
    this.setState({
      toggledProfile: true
    })
  }

  handleInactiveToggleProfile = () => {
    this.setState({
      toggledProfile: false
    })
  }

  handleLogOut = () => {
    localStorage.removeItem('userData')
    this.props.history.push('/')
  }

  handleShowLoginModal = () => {
    this.props.toggleShowLoginModal(true)
  }

  handleToggleHamburger = () => {
    const ham_state = !this.state.hamburgerToggled
    this.setState({
      hamburgerToggled: ham_state
    });
    if(ham_state){
      setTimeout(() => {
        this.setState({
          hamburgerToggled: false
        })
      }, 3000)
    }
  }

  render() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const { handleToggleHamburger } = this;
    const { hamburgerToggled } = this.state;
    let loginStatus = ''
    if (userData === null){
      loginStatus = 'not_login'
    } else if (ADMIN_USERS.includes(userData.email)) {
      loginStatus = 'admin_login'
    } else {
      loginStatus = 'login'
    }

    return (
      <nav className="bg-white shadow-lg w-full">
        <div className="flex lg:items-center justify-between lg:flex-wrap lg:mx-28 cursor-pointer">
          <div className="text-gray-800 m-4 mb-3">
            <Link className="flex" to="/">
              {/* <svg className="h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/></svg>
              <span className="font-semibold text-xl tracking-tight">Jelajah Holiday</span> */}
              <img src={logo} alt='Logo' className='w-32'/>
            </Link>
          </div>
          <div className="md:hidden mt-4 mr-4">
            <IconContext.Provider value={{ size: "1.5em" }}>
              <div>
                <GiHamburgerMenu onClick={() => handleToggleHamburger()}/>
              </div>
            </IconContext.Provider>
          </div>
          <div className="hidden md:block h-full flex w-auto">
            <div className="flex text-lg text-gray-800">
              <div className="m-3 h-full">
                <p className="hover:text-blue-500"><Link className="flex" to="/">Home</Link></p>
              </div>
              <div className="m-3 h-full">
                <p className="hover:text-blue-500"><Link className="flex" to="/destinasi-wisata">Destination</Link></p>
              </div>
              <div className="m-3 h-full">
                <p className="hover:text-blue-500"><Link className="flex" to="/our-tours" onClick={() => {window.location.href="/our-tours"}}>Tour</Link></p>
              </div>
              <div className="m-3 h-full" onClick={() => window.location.href='https://blog.jelajahholiday.com'}>
                <p className="hover:text-blue-500">Blog</p>
              </div>
              {
                loginStatus === 'not_login' ? (
                  <div className="m-3 h-full hidden">
                    <p className="hover:text-blue-500" onClick={this.handleShowLoginModal}>Login</p>
                    <LoginModal/>
                  </div>
                ) : null
              }
              {
                loginStatus === 'admin_login' ? (
                  <div className="m-3 h-full">
                    <p className="hover:text-pink-400 text-pink-800"><Link className="flex" to="/exclusive">Admin</Link></p>
                  </div>
                ) : null
              }
              {
                loginStatus !== 'not_login' ? (
                  <div className="ml-3 pt-2 relative z-50" onMouseEnter={this.handleActiveToggleProfile} onMouseLeave={this.handleInactiveToggleProfile}>
                  <div>
                    <button type="button" className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
                      <span className="sr-only">Open user menu</span>
                      <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                    </button>
                  </div>
                  {
                    this.state.toggledProfile ? (
                      <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={this.handleLogOut}>Log out</a>
                      </div>
                    ) : null
                  }
                </div>
                ) : null
              }
            </div>
          </div>
        </div>
        {
          hamburgerToggled ? (
            <div className="bg-white fixed z-10 right-0 w-2/3">
              <div className="m-3 h-full">
                <p className="hover:text-blue-500"><Link className="flex" to="/">Home</Link></p>
              </div>
              <div className="m-3 h-full">
                <p className="hover:text-blue-500"><Link className="flex" to="/destinasi-wisata">Destination</Link></p>
              </div>
              <div className="m-3 h-full">
                <p className="hover:text-blue-500"><Link className="flex" to="/our-tours" onClick={() => {window.location.href="/our-tours"}}>Tour</Link></p>
              </div>
            </div>
          ) : null
        }
      </nav>
    )
  }
}

const reduxState = (state) => ({
  showLoginModal  : state.showLoginModal
})

const reduxDispatch = (dispatch) => ({
  toggleShowLoginModal :  (data) => dispatch(toggleShowLoginModal(data))
})

export default withRouter(connect(reduxState, reduxDispatch)(Navbar));
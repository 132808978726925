import { database, storage } from '../../../firebase'
import { ENUM_STATE } from '../../../../utils/enums';

export const addTourDataAPI = (data) => (dispatch) => {
    const ref = database.ref('tours/').push(data)
    return ref
}

export const getToursDataAPI = () => (dispatch) => {
    const url = database.ref('tours/')
    return new Promise((resolve, reject) => {
        url.on('value', function(snapshot) {
        if (snapshot.val() != null) {
            const value = []
            Object.keys(snapshot.val()).map(key => {
            value.unshift({
                id: key,
                data: snapshot.val()[key]
            })
            })
            dispatch({type: 'CHANGE_TOURS', value: value})
            resolve(value)
        } else{
          dispatch({type: 'CHANGE_TOURS', value: []})
          resolve([])
        }
        })
    })
}

export const getActiveToursDataAPI = () => (dispatch) => {
  const url = database.ref('tours/')
  return new Promise((resolve, reject) => {
    url.orderByChild("state").equalTo(ENUM_STATE.ACTIVE).on('value', function(snapshot) {
      if (snapshot.val() != null) {
          const value = []
          Object.keys(snapshot.val()).map(key => {
          value.unshift({
              id: key,
              data: snapshot.val()[key]
          })
          })
          dispatch({type: 'CHANGE_TOURS', value: value})
          resolve(value)
      } else{
        dispatch({type: 'CHANGE_TOURS', value: []})
        resolve([])
      }
      })
  })
}

export const getTourDataByIdAPI = (data) => (dispatch) => {
  const url = database.ref('tours/' + data)
  return new Promise((resolve, reject) => {
    url.on('value', function(snapshot) {
      if (snapshot.val() != null) {
        resolve(snapshot.val())
      } else{
        resolve('Fail to retrieve data')
      }
    })
  })
}

export const getLatestActiveToursDataAPI = () => (dispatch) => {
  const url = database.ref('tours/')
  return new Promise((resolve, reject) => {
      url.orderByChild("state").equalTo(ENUM_STATE.ACTIVE).limitToLast(8).on('value', function(snapshot) {
      if (snapshot.val() != null) {
          const value = []
          Object.keys(snapshot.val()).map(key => {
          value.unshift({
              id: key,
              data: snapshot.val()[key]
          })
          })
          dispatch({type: 'CHANGE_TOURS', value: value})
          resolve(value)
      } else{
        dispatch({type: 'CHANGE_TOURS', value: []})
        resolve([])
      }
      })
  })
}

export const getToursDataByLocationAPI = (location) => (dispatch) => {
    const url = database.ref('tours/')
    return new Promise((resolve, reject) => {
        url.orderByChild("location").equalTo(location).on('value', function(snapshot) {
        if (snapshot.val() != null) {
            const value = []
            Object.keys(snapshot.val()).map(key => {
              value.unshift({
                  id: key,
                  data: snapshot.val()[key]
              })
            })
            dispatch({type: 'CHANGE_TOURS', value: value})
            resolve(value)
        } else{
          dispatch({type: 'CHANGE_TOURS', value: []})
          resolve([])
        }
        })
    })
}

export const getTourDataAPI = (id) => (dispatch) => {
    const url = database.ref('tours/' + id)
    return new Promise((resolve, reject) => {
      url.on('value', function(snapshot) {
        resolve(snapshot.val())
      })
    })
  }

export const addTourImage = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const url = storage.ref('tours/'+data.name).put(data);
        url.on(
        "state_changed",
        snapshot => {},
        error => {
            reject(false)
        },
        () => {
            storage.ref('tours').child(data.name).getDownloadURL().then(
            url => {
                resolve(url)
            }
            )
        }
        )
    })
}

export const toggleTourAPI = (data) => (dispatch) => {
    const url = database.ref('tours/'+ data.id)
    const newState = ( data.data.state === ENUM_STATE.ACTIVE ? ENUM_STATE.INACTIVE : ENUM_STATE.ACTIVE )
    let newValue = data.data
    newValue.state = newState
    return new Promise((resolve, reject) => {
      url.set(newValue, (err) => {
        if( err ) {
          reject(false)
        } else {
          resolve(true)
        }
      })
    })
  }

export const editTourAPI = (data) => (dispatch) => {
  const url = database.ref('tours/'+ data.id)
  return new Promise((resolve, reject) => {
    url.set(data.data, (err) => {
      if (err) {
        reject(false)
      } else {
        resolve(true)
      }
    })
  })
   
}
import React, { Component, Fragment } from 'react';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import { connect } from 'react-redux'
import { toggleAlert } from '../../../../config/redux/action';
import { ENUM_STATE } from '../../../../utils/enums'
import DestinationCart from '../../../organisms/DestinationCart';
import { ADMIN_USERS } from '../../../../utils/admin_users'
import Alert from '../../../../components/atoms/Alert';
import { addDestinationDataAPI, addDestinationImage, getAllDestinationsAPI, toggleDestinationAPI } from '../../../../config/redux/action/destination';
import EditDestinationModal from '../../../organisms/EditDestinationModal';

class ExclusiveDestination extends Component {
  state = {
    image: '',
    url: '',
    name: '',
    editMode: false,
    choosenDestination: {}
  }

  handleToggleEditMode = (destination) => {
    const currMode = this.state.editMode
    this.setState({
      editMode: !currMode,
      choosenDestination: destination
    })
  }

  componentDidMount() {
    const { getDestinations } = this.props;
    getDestinations()
  }

  handleChangeText = (e) => {
    this.setState({
      name: e.target.value
    })
  }

  handleChange = (e) => {
    if(e.target.files[0]){
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2){
          this.setState({
            image: e.target.files[0],
            url: reader.result
          })
        }
      } 
      reader.readAsDataURL(e.target.files[0])
    }
  }

  handleUpload = () => {
    const { image, name } = this.state;
    const { addDestination } = this.props;

    addDestinationImage(image).then(function(res) {
      const data = {
        name: name,
        img_url: res,
        state: ENUM_STATE.ACTIVE
      }
      addDestination(data)
    });
    this.setState({
      image: '',
      url: '',
      name: '',
    });
    this.handleSuccessAlert()
  }

  handleCancel = () => {
    this.setState({
      image: '',
      url: '',
      name: '',
    })
  }

  validateUserAccess = () => {
    const { history } = this.props;
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData === null) {
      history.push('/');
    }
    if (!ADMIN_USERS.includes(userData.email)) {
      history.push('/');
    }
  }

  handleToggle = (destination) => {
    const { toggleDestination } = this.props
    // TODO HANDLE TOGGLE
    toggleDestination(destination)
    this.handleSuccessAlert()
  }

  handleSuccessAlert = () => {
    const { toggleAlert } = this.props
    const alertData = {
      show: true,
      words: 'Data has been saved successfully',
      type: 'SUCCESS'
    }
    toggleAlert(alertData)
    setTimeout(function(){ toggleAlert({ show: false }) }, 2000);
  }
  
  render() {
    const { handleChange, 
            handleUpload, 
            handleChangeText, 
            handleCancel, 
            validateUserAccess, 
            handleToggle,
            handleToggleEditMode } = this;
    const { image, name, editMode, choosenDestination } = this.state;
    const { destinationList, showAlert } = this.props;
    validateUserAccess()

    return(  
      <div>
        <Alert 
          show={showAlert.show} 
          words={showAlert.words} 
          type={showAlert.type}/>
        <EditDestinationModal 
          isShown={editMode} 
          toggleFunc={handleToggleEditMode}
          destination={choosenDestination}
          />
        <div className="h-auto bg-blue-50 flex justify-evenly items-center p-10">
          <div className="flex flex-col items-center">
            { 
              image ? 
                (
                  <Fragment>
                    <img className="border border-blue-400 w-72 h-80 m-5 object-cover rounded-lg" src={ this.state.url } alt='Destination'/>
                  </Fragment>
                ) :
                (
                  <Fragment>
                    <Input type="file" onChange={(e) => handleChange(e)}/>
                  </Fragment>
                )
            }
            <Input type="text" onChange={(e) => handleChangeText(e)} placeholder="Nama Destinasi" value={name}/>
            <div>
              <Button onClick={handleUpload} title="UPLOAD"/>
              {
                image ?
                (
                  <Button onClick={handleCancel} title="CANCEL"/>
                ) : null
              }
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-center max-w-screen-2xl m-auto">
          {
            destinationList.length > 0 ? (
              <Fragment>
                {
                  destinationList.map(destination => {
                    return ( 
                      <div className="flex flex-col m-5" key={destination.id}>
                        <DestinationCart destination={destination} key={destination.id}/>
                        <div>
                          {
                            destination.data.state === ENUM_STATE.ACTIVE ? (
                              <Button title="ACTIVE" onClick={() => handleToggle(destination)}/>
                            ) : (
                              <Button title="INACTIVE" onClick={() => handleToggle(destination)} model='inactive' />
                            )
                          }
                          <Button title="EDIT" onClick={() => handleToggleEditMode(destination) }/>
                        </div>
                      </div>
                    )
                  })
                }
              </Fragment>
            ) : null
          }
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  destinationList   : state.destinations,
  showAlert         : state.showAlert
})

const reduxDispatch = (dispatch) => ({
  addDestination      : (data) => dispatch(addDestinationDataAPI(data)),
  getDestinations     : () => dispatch(getAllDestinationsAPI()),
  toggleDestination   : (data) => dispatch(toggleDestinationAPI(data)),
  toggleAlert         : (data) => dispatch(toggleAlert(data))
})

export default connect(reduxState, reduxDispatch)(ExclusiveDestination);
import axios from "axios";
import { useState } from "react"
import Button from "../../../components/atoms/Button"
import { mapPaymentStatus } from "../../../utils/mapPaymentStatus"
import { toIDR } from "../../../utils/toIDR"

const PaymentDetailModal = ({ paymentDetail, toggleModal }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const additionalFees = JSON.parse(paymentDetail.additionalFees)

  const handleSendEmail = async () => {
    setLoading(true)
    try{
      await axios.post(
        `${process.env.REACT_APP_BE_HOST}/payments/${paymentDetail.id}/notify-user`,
        {}
      )
      setMessage('Email sent to customer')
    } catch(err) {
      setMessage('Faild to send email')
    }
    setLoading(false)
  }

  return(
    <div className="overflow-x-hidden fixed z-50 w-full m-auto bg-black bg-opacity-20 h-full top-0">
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">       
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 top-20">
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {mapPaymentStatus(paymentDetail.status)} Payment Detail <span className="text-blue-600">{paymentDetail.paymentNumber}</span>  
            </h3>
            <button 
              type="button" 
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" 
              onClick={toggleModal}>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
            </button>
          </div>
            
          <div className="p-6 space-y-6">
            <p className="leading-none"><span className="font-semibold">Created Date : </span>{(new Date(paymentDetail.createdAt)).toLocaleString()}</p>
            <p className="leading-none"><span className="font-semibold">Customer : </span>{`${paymentDetail.customerName} - ${paymentDetail.customerEmail} - ${paymentDetail.customerPhoneNumber}` }</p>
            <p className="leading-none"><span className="font-semibold">Item : </span>{`${paymentDetail.quantity} Pax - ${paymentDetail.tourName}` }</p>
            <p className="leading-none"><span className="font-semibold">Item Total Price : </span>Rp {toIDR(paymentDetail.tourTotalPrice)}</p>
            <p className="leading-none"><span className="font-semibold">Additional Fee : </span></p>
            <ul className="leading-none list-disc ml-10">
              {
                additionalFees.map(fee => (
                  <li key={fee.type}>{fee.type} : Rp {toIDR(fee.value)}</li>
                ))
              }
            </ul>
            <p className="leading-none"><span className="font-semibold">Checkout URL : </span><a className="text-blue-500" href={paymentDetail.checkoutUrl} target="_blank" rel="noreferrer">{paymentDetail.checkoutUrl}</a></p>
            <p className="leading-none">
              <span className="font-semibold">Charged Amount : </span> 
              <span className="text-xl font-semibold text-yellow-500">Rp{toIDR(paymentDetail.chargedAmount)}</span></p>
          </div>

          {/* TODO ADD CALL TO ACTION HERE */}
          <div className="py-4 border-t border-gray-200 dark:border-gray-600">
            <div className="ml-6">
              <Button loading={loading} title='Send Email Invoice' onClick={handleSendEmail}/>
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentDetailModal
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDxeV_kL0jihBOgqBWO-GUM_hqZaDIVKxU",
  authDomain: "jb-project-9394b.firebaseapp.com",
  projectId: "jb-project-9394b",
  storageBucket: "jb-project-9394b.appspot.com",
  messagingSenderId: "557143583406",
  appId: "1:557143583406:web:212757d82fec5e0a161b3f",
  measurementId: "G-2XY69SBS2Q"
};
// Initialize Firebase
// firebase.analytics();

firebase.initializeApp(firebaseConfig);
export const database = firebase.database();
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export default firebase;
import Status from "../../components/atoms/Status"
import { PAYMENT_STATE } from "../enums"

export const mapPaymentStatus = (enumStatus) => {
  switch(enumStatus){
    case PAYMENT_STATE.PENDING:
      return <Status value='pending'/>
    case PAYMENT_STATE.PAID:
        return <Status value='paid'/>
    case PAYMENT_STATE.EXPIRED:
      return <Status value='expired'/>
    case PAYMENT_STATE.FAILED:
      return <Status value='failed'/>
    default:
      return 'UNDEFINED'
  }
}
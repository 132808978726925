const Status = ({value}) => {
  if(value === 'pending'){
    return(
      <div className="bg-yellow-300 rounded inline-block">
        <p className="p-1 px-3">Pending</p>
      </div>
    )
  }
  if(value === 'paid'){
    return(
      <div className="bg-green-400 rounded inline-block">
        <p className="p-1 px-3">Paid</p>
      </div>
    )
  }
  if(value === 'expired'){
    return(
      <div className="bg-gray-400 rounded inline-block">
        <p className="p-1 px-3">Expired</p>
      </div>
    )
  }
  if(value === 'failed'){
    return(
      <div className="bg-red-400 rounded inline-block">
        <p className="p-1 px-3">Failed</p>
      </div>
    )
  }
}

export default Status
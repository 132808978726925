import { useEffect } from "react"
import { useState } from "react"

const Counter = ({minThreshold, onChange}) => {
  const [quantity, setQuantity] = useState(0)

  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  }

  const decreaseQuantity = () => {
    if(quantity > minThreshold){
      setQuantity(quantity - 1)
    }
  }

  const handleOnChange = (e) => {
    const value = e.target.value
    const isnum = /^\d+$/.test(value);
    if(isnum){
      const num = parseInt(value)
      if(num > minThreshold){
        setQuantity(parseInt(value))
      }
    }
  }

  useEffect(()=> {
    setQuantity(minThreshold)
  },[minThreshold])

  useEffect(() => {
    onChange(quantity)
  },[quantity])

  return(
    <div>
      <div className="flex flex-row w-full rounded-lg relative border">
        <button onClick={decreaseQuantity} className="text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full px-2 rounded-l cursor-pointer outline-none border-r">
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
          className="appearance-none outline-none focus:outline-none text-center w-full text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number" 
          type='text'
          value={quantity}
          onChange={handleOnChange}
          />
      <button onClick={increaseQuantity} className="text-gray-600 hover:text-gray-700 hover:bg-gray-100 h-full px-2 rounded-r cursor-pointer border-l">
        <span className="m-auto text-2xl font-thin">+</span>
      </button>
      </div>
    </div>
  )
}

export default Counter
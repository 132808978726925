/* eslint-disable array-callback-return */
import { database, storage } from '../../../firebase'
import { ENUM_STATE } from '../../../../utils/enums';

export const getDestinationDataAPI = (id) => (dispatch) => {
  const url = database.ref('destinations/' + id)
  return new Promise((resolve, reject) => {
    url.on('value', function(snapshot) {
      resolve(snapshot.val())
    })
  })
}

export const getAllDestinationsAPI = () => (dispatch) => {
  const url = database.ref('destinations')
  return new Promise((resolve, reject) => {
    url.on('value', function(snapshot) {
      const value = []
      if(snapshot.val() != null) {
        Object.keys(snapshot.val()).map(key => {
          value.push({
            id: key,
            data: snapshot.val()[key]
          })
        })
        dispatch({type: 'CHANGE_DESTINATIONS', value: value})
        resolve(value)
      } else {
        reject(false)
      }
    })
  })
}

export const getActiveDestinationsAPI = () => (dispatch) => {
  const url = database.ref('destinations')
  return new Promise((resolve, reject) => {
    url.orderByChild("state").equalTo(ENUM_STATE.ACTIVE).on('value', function(snapshot) {
      const value = []
      if(snapshot.val() != null) {
        Object.keys(snapshot.val()).map(key => {
          value.push({
            id: key,
            data: snapshot.val()[key]
          })
        })
        dispatch({type: 'CHANGE_DESTINATIONS', value: value})
        resolve(value)
      } else {
        reject(false)
      }
    })
  })
}

export const getLatestActiveDestinationsAPI = () => (dispatch) => {
  const url = database.ref('destinations')
  return new Promise((resolve, reject) => {
    url.orderByChild("state").equalTo(1).limitToLast(8).on('value', function(snapshot) {
      const value = []
      if(snapshot.val() != null) {
        Object.keys(snapshot.val()).map(key => {
          value.push({
            id: key,
            data: snapshot.val()[key]
          })
        })
        dispatch({type: 'CHANGE_DESTINATIONS', value: value})
        resolve(value)
      } else {
        reject(false)
      }
    })
  })
}

export const addDestinationDataAPI = (data) => (dispatch) => {
  database.ref('destinations/').push({
    name: data.name,
    img_url: data.img_url,
    state: data.state
  })
}

export const editDestinationDataAPI = (data) => {
  return new Promise((resolve, reject) => {
    database.ref(`destinations/${data.id}`).set({
      name: data.name,
      img_url: data.img_url,
      state: data.state
    }, (err) => {
      if(err){
        reject(false)
      }else{
        resolve(true)
      }
    })
  })
}

export const toggleDestinationAPI = (data) => (dispatch) => {
  const url = database.ref('destinations/'+ data.id)
  const newState = ( data.data.state === ENUM_STATE.ACTIVE ? ENUM_STATE.INACTIVE : ENUM_STATE.ACTIVE )
  const newValue = {
    name: data.data.name,
    img_url: data.data.img_url,
    state: newState
  }
  return new Promise((resolve, reject) => {
    url.set(newValue, (err) => {
      if( err ) {
        reject(false)
      } else {
        resolve(true)
      }
    })
  })
}

// Storage
export const addDestinationImage = (data) => {
  return new Promise((resolve, reject) => {
    const url = storage.ref('destinations/'+data.name).put(data);
    url.on(
      "state_changed",
      snapshot => {},
      error => {
        reject(false)
      },
      () => {
        storage.ref('destinations').child(data.name).getDownloadURL().then(
          url => {
            resolve(url)
          }
        )
      }
    )
  })
}
import React from 'react';
import { REFUND_OPTION } from '../../../utils/enums';

const Registration = ({dp_minimal, dp_due_date, full_pay_due_date, refund_option, customRefundOptions, paymentTermOptions}) => {
  return (
    <div className='ml-2'>
      <div>
        <ol className='list-decimal'>
          <li>
            Format registrasi peserta tour :
            <ul className='ml-10 list-disc'>
              <li>Nama lengkap</li>
              <li>Alamat</li>
              <li>Email</li>
              <li>No. HP</li>
            </ul>
          </li>
          <li>Dilarang melakukan pembayaran sebelum menerima email konfirmasi dari jelajah.holiday@gmail.com</li>
          <li>Mekanisme pembayaran (paket tour + tips tour guide) :
            { paymentTermOptions ? (
              <table className="table-auto m-auto">
                <thead className="border-2 border-gray-800">
                  <tr>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center">Term Payment</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center">Due Date</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center">Nominal</th>
                  </tr>
                </thead>
                <tbody>
                  { paymentTermOptions.map(paymentTermOption => (
                    <tr key={paymentTermOption.term}>
                      <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{paymentTermOption.term}</td>
                      <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{paymentTermOption.timeframe}</td>
                      <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{paymentTermOption.nominal}</td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <ul className='ml-10 list-disc'>
                <li>Down Payment {dp_minimal || '50%' } (Maks. {dp_due_date || 'H-30'})</li>
                <li>Full Payment (Maks. {full_pay_due_date || 'H-7'})</li>
              </ul>
            )}   
          </li>
          <li>Apabila peserta memesan tiket pesawat, maka lakukan pembayaran full tiket pesawat terlebih dahulu, kemudian pembayaran down payment dan final payment. Seluruh pembelian tiket pesawat bersifat non-cancelable dan non-refundable</li>
          <li>Silakan konfirmasi pembayaran dengan melampirkan bukti pembayaran ke Jelajah Holiday melalui whatsapp / email.</li>
          <li>Pembayaran pertama atau down payment bersifat non-cancelable dan non-refundable sesuai ketentuan pasal 1464 Kitab Undang-undang (KUH) Perdata : “Jika pembelian dilakukan dengan memberi uang panjar, maka salah satu pihak tak dapat membatalkan pembelian itu dengan menyuruh memiliki atau mengembalikan uang panjarnya."</li>
          <li>Mekanisme pembatalan oleh peserta :
            {
              refund_option === REFUND_OPTION.MAX_14 ?  
              <table className="table-auto m-auto">
                <thead className="border-2 border-gray-800">
                  <tr>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Sebelum Tour</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Down Payment</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Full Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">> 14 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">-</td>
                  </tr>
                  <tr>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">8 - 14 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">75% refund</td>
                  </tr>
                  <tr>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">0 - 7 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                  </tr>
                </tbody>
              </table>

              : null
            }

            {
              refund_option === REFUND_OPTION.MAX_30 ? 
              <table className="table-auto m-auto">
                <thead className="border-2 border-gray-800">
                  <tr>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Sebelum Tour</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Down Payment</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Full Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">> 30 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">-</td>
                  </tr>
                  <tr>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">21 - 30 har</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">75% refund</td>
                  </tr>
                  <tr>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">0 - 21 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                  </tr>
                </tbody>
              </table>
              : null
            }

            {
              refund_option === REFUND_OPTION.MAX_7 ?
              <table className="table-auto m-auto">
                <thead className="border-2 border-gray-800">
                  <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Sebelum Tour</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Down Payment</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Full Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700">
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">> 7 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">-</td>
                  </tr>
                  <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700">
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">6 - 7 har</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">75% refund</td>
                  </tr>
                  <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700">
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">0 - 6 hari</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">No refund</td>
                  </tr>
                </tbody>
              </table>
              : null
            }

            {
              refund_option === REFUND_OPTION.CUSTOM ? 
              <table className="table-auto m-auto">
                <thead className="border-2 border-gray-800">
                  <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Sebelum Tour</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Down Payment</th>
                    <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center">Full Payment</th>
                  </tr>
                </thead>
                <tbody>
                  { customRefundOptions.map(refundOption => (
                    <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700">
                      <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{refundOption.timeframe}</td>
                      <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{refundOption.downpayment}</td>
                      <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{refundOption.fullpayment}</td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
              : null
            }

            Proses pengembalian dana (refund) dilakukan paling lambat 14 hari setelah tanggal pengajuan pembatalan tour.
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Registration;
import React from 'react';
import { Link } from 'react-router-dom';

const DestinationCart = ({destination}) => {
  return (
    <div className="border rounded-lg shadow overflow-hidden w-72 h-80 md:w-72 md:h-80 relative cursor-pointer transform hover:-translate-y-1 transition duration-100 hover:shadow-2xl">
      <Link to={ ( "/our-tours?location=" + destination.id + "&name=" + destination.data.name ) }>
        <img className="absolute inset-0 h-full w-full object-cover" src={destination.data.img_url} alt='Destination'/>
        <div className="absolute inset-0 h-full bg-gradient-to-t from-gray-900 via-transparent"></div>
        <div className="flex h-full items-end justify-left relative">
          <h1 className="text-2xl text-white font-semibold ml-3 mb-3 shadow-2xl">{destination.data.name}</h1>
        </div>
      </Link>
    </div>
  )
}

export default DestinationCart;
import { useEffect } from "react"
import { useState } from "react"
import Button from "../../../components/atoms/Button"
import Input from "../../../components/atoms/Input"
import { addDestinationImage, editDestinationDataAPI } from "../../../config/redux/action/destination"

const EditDestinationModal = ({ isShown, toggleFunc, destination }) => {
  const [name, setName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [imgUrl, setImgUrl] = useState(null)
  const [newImgUrl, setNewImgUrl] = useState(null)
  const [newImgFile, setNewImgFile] = useState(null)
  const [message, setMessage] = useState('')

  const handleSubmit = async () => {
    setLoading(true)
    setMessage('')
    let uploadedImgUrl
    if(newImgFile){
      uploadedImgUrl = await addDestinationImage(newImgFile)
    }
  
    const payload = {
      id: destination.id,
      name: name,
      img_url: uploadedImgUrl || imgUrl,
      state: destination.data.state
    }
    await editDestinationDataAPI(payload)
    setLoading(false)
    setMessage('Saved')
    window.location.reload()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(!name){
      setName(destination.data?.name)
      setImgUrl(destination.data?.img_url)
    }
  });

  const handleImageChange = (e) => {
    if(e.target.files[0]){
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2){
            setNewImgFile(e.target.files[0])
            setNewImgUrl(reader.result)
        }
      } 
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const cancelImageChange = () => {
    setNewImgFile(null)
    setNewImgUrl(null)
  }

  if(isShown){
    return(
      <div className="overflow-x-hidden fixed z-50 w-full m-auto bg-black bg-opacity-20 h-full top-0">
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">       
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 top-5">
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Get latest information from us!
              </h3>
              <button 
                type="button" 
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" 
                onClick={toggleFunc}>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  <span className="sr-only">Close modal</span>
              </button>
            </div>
              
            <div className="p-6 space-y-6">
              <Input 
                label="Name"
                placeholder="Your Name"
                onChange={ e => setName(e.target.value) }
                value={ name }
                />
                <div className='flex'>
                <div className='m-1'>
                  <img 
                    className="border border-blue-400 w-72 h-80 mb-0 object-cover" 
                    src={ newImgUrl || imgUrl } 
                    alt='Destination'/>
                  { newImgUrl ? (
                    <Button 
                      title='Cancel'
                      onClick={cancelImageChange}
                      color='yellow'/>
                  ) : (
                    <Input 
                    type='file' 
                    label='Change Image' 
                    model='button'
                    onChange={handleImageChange}/>
                  ) }
                </div>
              </div>
            </div>
              
            <div className="pb-6 border-t border-gray-200 dark:border-gray-600">
              <div className="ml-6">
                { loading ? (
                  <Button loading={true}/>
                ) : (
                  <Button 
                  title="Update!"
                  color="yellow"
                  onClick={handleSubmit}
                  />
                ) }
                <p className="ml-1 text-green-500">{message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return(<div></div>)
  }
}

export default EditDestinationModal
import React from 'react';

const Alert = ({show, words, type}) => {
  if(show) {
    if(type==='SUCCESS'){
      return(
        <div className="fixed w-full mt-1 z-50">
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative w-max text-center m-auto" role="alert">
            <span className="block sm:inline">{words}</span>
          </div>
        </div>
      )
    }
  }
  return null
}

export default Alert;
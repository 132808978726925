import React from 'react';

const Itinerary = ({content}) => {
  return (
    <div>
      <div className="text-sm md:text-lg" dangerouslySetInnerHTML={{ __html: content }} />
      {/* <div className="bg-blue-500 rounded border-2 border-blue-500">
        <div className="m-1 ml-3">
          <h2 className="font-semibold text-white">Day 1 </h2>
        </div>
        <ul className="divide-y divide-gray-700 bg-white">
          <li className="m-1 ml-3">Activity 1</li>
          <li className="m-1 ml-3">Activity 2</li>
        </ul>
      </div> */}
    </div>
  )
}

export default Itinerary;
import { firestore } from '../../../firebase'

export const getMapValue = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firestore.collection("mappers").where("key", "==", key)
      .get()
      .then( (snapshot) => {
        if(snapshot != null) {
          snapshot.forEach((doc) => {
            resolve(doc.data().value)
          })
        } else {
          reject(false)
        }
      })
  })
}

export const createMapValue = (key, val) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const data = {
      key: key,
      value: val
    }
    firestore.collection("mappers").add(data)
  })
}
import React, { Component, Fragment } from 'react';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';
import Textarea from '../../../components/atoms/Textarea';
import { connect } from 'react-redux'
import { addNotesDataAPI, getNotesDataAPI, updateNoteDataAPI, deleteNoteDataAPI } from '../../../config/redux/action'

class SimpleNotes extends Component {
  state = {
    title: '',
    content: '',
    date: '',
    buttonText: 'SUBMIT',
    noteId: '',
    createdAt: ''
  }
  
  componentDidMount() {
    const { getNotes } = this.props;
    
    const userData = JSON.parse(localStorage.getItem('userData'))
    const data = {
      userId: userData.uid
    }

    getNotes(data)
  }

  handleChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSaveNotes = () => {
    const { title, content, noteId, createdAt } = this.state;
    const { saveNotes, updateNote } = this.props;
    const userData = JSON.parse(localStorage.getItem('userData'))

    if (this.buttonText === 'SUBMIT'){
      const data = {
        title: title,
        content: content, 
        date: new Date().getTime(),
        userId: userData.uid
      }
      saveNotes(data)
    } else {
      const data = {
        title: title,
        content: content,
        userId: userData.uid,
        date: createdAt,
        noteId: noteId
      }
      updateNote(data)
    }
  }

  updateNotes = (note) => {
    this.setState({
      title: note.data.title,
      content: note.data.content,
      buttonText: 'UPDATE',
      noteId: note.id,
      createdAt: note.data.date
    })
  }

  cancelUpdate = () => {
    this.setState({
      title: '',
      content: '',
      buttonText: 'SUBMIT'
    })
  }

  handleDeleteNote = (e, note) => {
    const { deleteNote } = this.props
    const userData = JSON.parse(localStorage.getItem('userData'))
    e.stopPropagation()
    const data = {
      userId: userData.uid,
      noteId: note.id
    }
    deleteNote(data)
  }

  render() {
    const { title, content, buttonText } = this.state;
    const { notes } = this.props;
    const { updateNotes, cancelUpdate, handleSaveNotes, handleChangeText, handleDeleteNote } = this;
    return (
      <div>
        <div className="bg-gray-100">
          <p className="text-center pt-5 text-2xl font-light">Simple Notes Page</p>
          <br/>
          <div className="w-6/12 mx-auto pb-9">
            <Input id="title" placeholder="title" label="Title" value={title} onChange={handleChangeText}/>
            <Textarea id="content" label="Content" placeholder="content" value={content} onChange={handleChangeText}/>
            <Button title={this.state.buttonText} onClick={handleSaveNotes}/>
            {
              buttonText === 'UPDATE' ? (
                <Button title="CANCEL" onClick={cancelUpdate}/>
              ) : null
            }
          </div>
        </div>
        {
          notes.length > 0 ? (
            <Fragment>
              {
                notes.map(note => {
                  return (
                    <div onClick={() => updateNotes(note)} className="border-1 border-gray-300 m-4 shadow-md transform hover:-translate-y-1 transition duration-100" key={note.id}>
                      <div className="m-4 pb-2 pt-2 cursor-pointer">
                        <p className="text-2xl font-bold" >{note.data.title}</p>
                        <p className="text-sm font-light text-gray-500">{note.data.date}</p>
                        <p className="mt-4">{note.data.content}</p>
                        <div className="flex justify-end">
                          <Button title="DELETE" onClick={(e) => handleDeleteNote(e, note)}/>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Fragment>
          ) 
          : 
          null
        }
      </div>
    )
  }
}

const reduxState = (state) => ({
  userData  : state.user,
  notes     : state.notes
})

const reduxDispatch = (dispatch) => ({
  saveNotes : (data) => dispatch(addNotesDataAPI(data)),
  getNotes  : (data) => dispatch(getNotesDataAPI(data)),
  updateNote : (data) => dispatch(updateNoteDataAPI(data)),
  deleteNote : (data) => dispatch(deleteNoteDataAPI(data))
})

export default connect(reduxState, reduxDispatch)(SimpleNotes);
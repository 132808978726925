import { firestore } from "../../../firebase";

export const retrieveTourCodes = async () => {
  const docRef = firestore.collection('tourCode').doc('tourCode');

  try {
    const docSnapshot = await docRef.get();
    if (docSnapshot.exists) {
      // Data exists for the document
      const data = docSnapshot.data();
      return data.tourCode;
    } else {
      // Document doesn't exist
      return null;
    }
  } catch (error) {
    console.error('Error fetching tourCode data:', error);
    return null;
  }
};

export const saveOfflineInvoice = async (payload) => {
  const { invoiceCounter } = payload;
  try {
    const docRef = firestore.collection('offlineInvoice').doc(invoiceCounter);
    await docRef.set(payload);

  } catch (error) {
    console.error('Error saving data to Firestore:', error);
  }
}

export const generateInvoiceCounter = async () => {
  try {
    const querySnapshot = await firestore
      .collection('offlineInvoice')
      .orderBy('invoiceCounter', 'desc')
      .limit(1)
      .get();

    if (querySnapshot.empty) {
      return '0';
    }

    const latestDocument = querySnapshot.docs[0];
    const latestData = latestDocument.data();
    
    return String(Number(latestData.invoiceCounter) + 1);
  } catch (error) {
    console.error('Error retrieving data from Firestore:', error);
    return '0';
  }
}

export const retrieveInvoices = async () => {
  try {
    const querySnapshot = await firestore
      .collection('offlineInvoice')
      .orderBy('createdAt', 'desc')
      .get();

    if (querySnapshot.empty) {
      return '0';
    }

    let data = []
    querySnapshot.docs.map((doc)=> {
      data.push(doc.data())
    })
    
    return data
  } catch (error) {
    console.error('Error retrieving data from Firestore:', error);
    return '0';
  }
}

export const fetchInvoiceData = async (id) => {
  try {
    const querySnapshot = await firestore
      .collection('offlineInvoice')
      .where('invoiceCounter', '==', id)
      .limit(1)
      .get();

    if (!querySnapshot.empty) {
      // Get the first (and only) document from the querySnapshot
      const docSnapshot = querySnapshot.docs[0];
      const data = docSnapshot.data();
      return data;
    } else {
      console.log('Document not found.');
    }
  } catch (error) {
    console.error('Error retrieving data from Firestore:', error);
  }
};

import React, { Component } from 'react';
import Input from '../../../../../components/atoms/Input';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from '../../../../../components/atoms/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENUM_STATE, TRIP_COST_CATEGORY, PRICE_VIEW, REFUND_OPTION } from '../../../../../utils/enums'
import { addTourDataAPI, addTourImage } from '../../../../../config/redux/action/tour';
import { Fragment } from 'react';
import { TRIP_TYPE } from '../../../../../utils/enums'
import { getAllDestinationsAPI } from '../../../../../config/redux/action/destination';
import { ADMIN_USERS } from '../../../../../utils/admin_users';
import { createMapValue } from '../../../../../config/redux/action/key_val';


class ExclusiveTourCreate extends Component {  
    state = {
        title: "",
        itinerary: EditorState.createEmpty(),
        tnc: EditorState.createEmpty(),
        include: EditorState.createEmpty(),
        exclude: EditorState.createEmpty(),
        duration_day: "",
        duration_night: "",
        cost_category: "",
        min_quota: "",
        price_options: 0,
        prices: [],
        tour_dates: [],
        location: "",
        image_1: "",
        image_url_1: "",
        image_2: "",
        image_url_2: "",
        image_3: "",
        image_url_3: "",
        image_4: "",
        image_url_4: "",
        image_5: "",
        image_url_5: "",
        trip_type: "",
        onProgressSubmit: "",
        price_view_option: "",
        dp_minimal: "",
        dp_due_date: "",
        full_pay_due_date: "",
        refund_option: ""
    }

    validateUserAccess = () => {
        const { history } = this.props;
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData === null) {
          history.push('/');
        }
        else if (!ADMIN_USERS.includes(userData.email)) {
          history.push('/');
        }
      }

    componentDidMount() {
        const { getDestinations } = this.props;
        getDestinations();
    }

    handleChangeText = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    
    handleItinerary = (itinerary) => {
        this.setState({
            itinerary
        })
    }

    handleTnc = (tnc) => {
        this.setState({
            tnc
        })
    }

    handleInclude = (include) => {
        this.setState({
            include
        })
    }

    handleExclude = (exclude) => {
        this.setState({
            exclude
        })
    }

    convertHtmlStyle = (str) => {
      return draftToHtml(convertToRaw(str.getCurrentContent() ))
          .replaceAll("<ul>", "<ul class=\"ml-8 list-outside list-disc\">")
          .replaceAll("<ol>", "<ol class=\"ml-8 list-outside list-decimal\">")
          .replaceAll("<h2>", "<h2 class=\"mt-5 ml-2\">")
    }

    handleCheckClick = (e) => {
        this.setState({
            [e.target.id]: e.target.checked
        })
    }

    handleSubmit = () => {
        const { title, 
                itinerary, 
                tnc, 
                include, 
                exclude,
                cost_category,
                min_quota,
                duration_day,
                duration_night,
                prices,
                tour_dates,
                location,
                trip_type,
                image_1,
                image_2,
                image_3,
                image_4,
                image_5,
                price_view_option,
                dp_minimal,
                dp_due_date,
                full_pay_due_date,
                refund_option
                } = this.state
        this.setState(
            {
                onProgressSubmit: true
            }
        )
        const { convertHtmlStyle } = this;
        const { addTour, uploadImage, history } = this.props;
        const data = {
            title: title,
            itinerary: convertHtmlStyle(itinerary),
            tnc: convertHtmlStyle(tnc),
            include: convertHtmlStyle(include),
            exclude: convertHtmlStyle(exclude),
            duration_day: duration_day,
            duration_night: duration_night,
            cost_category: cost_category,
            min_quota: min_quota,
            state: ENUM_STATE.ACTIVE,
            prices: prices,
            tour_dates: tour_dates,
            location: location,
            trip_type: trip_type,
            image_url_1: "",
            image_url_2: "",
            image_url_3: "",
            image_url_4: "",
            image_url_5: "",
            price_view_option: price_view_option,
            dp_minimal: dp_minimal,
            dp_due_date: dp_due_date,
            full_pay_due_date: full_pay_due_date,
            refund_option: refund_option
        }
        uploadImage(image_1).then(function(res) {
            data.image_url_1 = res
            uploadImage(image_2).then(function(res) {
                data.image_url_2 = res
                uploadImage(image_3).then(function(res) {
                    data.image_url_3 = res
                    uploadImage(image_4).then(function(res) {
                        data.image_url_4 = res
                        uploadImage(image_5).then(function(res) {
                            data.image_url_5 = res
                            addTour(data)
                            history.push('/')
                        })
                    })
                })
            })
        })
    }

    handleIncreasePriceOption = () => {
        const price_options = this.state.price_options
        this.setState({
            price_options: (price_options+1)    
        })
    }

    handleResetPriceOption = () => {
        this.setState({
            price_options: 0,
            prices: [] 
        })
    }

    handleChangePriceOptions = (e) => {
        var splitter = e.target.id.split("_")
        var index = parseInt(splitter[1])
        var obj_name = splitter[0]
        var curr_val = this.state.prices
        if (curr_val[index] === undefined ) {
            curr_val[index] = {
                price: "",
                minquota: "",
                origin: ""
            }
        }
        curr_val[index][obj_name] = e.target.value
        this.setState({
            prices: curr_val
        })
    }

    handleSelectLocation = (e) => {
        this.setState({
            location: e.target.value
        })
    }

    handleSelectTripType = (e) => {
        this.setState({
            trip_type: e.target.value
        })
    }

    handleSelectCostCategory = (e) => {
        this.setState({
            cost_category: e.target.value
        })
    }

    handleChangeTourDates = (e) => {
      this.setState({
        [e.target.id]: e.target.value.split(",")
      })
    }

    handleImageChange = (e) => {
        var id = ("image_" + e.target.id)
        var url = ("image_url_" + e.target.id)
        if(e.target.files[0]){
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2){
                this.setState({
                    [id]: e.target.files[0],
                    [url]: reader.result
                })
            }
          } 
          reader.readAsDataURL(e.target.files[0])
        }
      }

      handleImageDelete = (e) => {
        var id = ("image_" + e.target.id)
        var url = ("image_url_" + e.target.id)
        this.setState({
            [id]: null,
            [url]: null
        })
      }
    
    render() {
        const { destinationList } = this.props
        const { location, 
                itinerary, 
                tnc, 
                include, 
                exclude, 
                cost_category, 
                price_options,
                tour_dates,
                trip_type,
                image_1,
                image_url_1,
                image_2,
                image_url_2,
                image_3,
                image_url_3,
                image_4,
                image_url_4,
                image_5,
                image_url_5,
                onProgressSubmit,
                price_view_option,
                dp_minimal,
                dp_due_date,
                full_pay_due_date,
                refund_option } = this.state;
        const { handleChangeText, 
                handleSelectLocation, 
                handleItinerary, 
                handleChangePriceOptions, 
                handleTnc, 
                handleInclude, 
                handleExclude, 
                handleSubmit,
                handleIncreasePriceOption, 
                handleResetPriceOption,
                handleSelectTripType,
                handleImageChange,
                handleImageDelete,
                handleSelectCostCategory,
                handleChangeTourDates } = this;
        //const str = draftToHtml(convertToRaw(itinerary.getCurrentContent() )).replace("<ul>", "<ul className=\"list-inside list-disc\">")
        let priceItems = []
        for(let i = 0; i < price_options; i++) {
            priceItems.push(<div key={i}>
                <p className="pt-6 ml-2 mr-2"> Harga</p>
                <Input
                    id ={"price_" + i}
                    placeholder="200.000"
                    onChange={handleChangePriceOptions}
                />
                <p className="pt-6 ml-2 mr-2"> Perorang untuk minimal pemesanan </p>
                <Input
                    id ={"minquota_" + i}
                    placeholder="5"
                    onChange={handleChangePriceOptions}
                />
                <p className="pt-6 ml-2 mr-2"> Keberangkatan dari </p>
                <Input
                    id ={"origin_" + i}
                    placeholder="Jakarta"
                    onChange={handleChangePriceOptions}
                />
                <div className=" mt-2 h-1 bg-gray-400"></div>
            </div>);
        }

        let processed_tour_dates = ""
        for(let i = 0; i < tour_dates.length; i++){
          if( i > 0) {
            processed_tour_dates = processed_tour_dates + "," + tour_dates[i]
          } else {
            processed_tour_dates = tour_dates[i]
          }
        }

        this.validateUserAccess()
        return(
        <div className="w-full bg-gray-50">
            <div className="w-9/12 m-auto">
                <Input 
                    label="Title"
                    id="title"
                    placeholder="Paket Open Trip..."
                    onChange={handleChangeText}
                    />
                
                <label className="block font-semibold mt-2"/>Lokasi :
                <select className="ml-2 mt-2 border-2 rounded-sm" 
                        value={location} 
                        onChange={handleSelectLocation}>
                    <option value="">Select Here</option>
                    {
                        destinationList.map(destination => {
                            return(
                                <option key={destination.id} value={destination.id}>{destination.data.name}</option>
                            )
                        })
                    }
                </select>

                <Input
                  label="Tour Dates"
                  id="tour_dates"
                  placeholder="Date 1,Date 2, ..."
                  onChange={handleChangeTourDates}
                  value={processed_tour_dates}
                  />

                <label className="block font-semibold mt-2"/>Tipe Trip :
                <select className="ml-2 mt-2 border-2 rounded-sm" 
                        value={trip_type} 
                        onChange={handleSelectTripType}
                        >
                    <option value="">Select Here</option>
                    <option value={TRIP_TYPE.OPEN_TRIP}>Open Trip</option>
                    <option value={TRIP_TYPE.PRIVATE_TRIP}>Private Trip</option>
                    <option value={TRIP_TYPE.FAMILY_TRIP}>Family Trip</option>
                </select>

                <label className="block font-semibold mt-2"/>Cost Category :
                <select className="ml-2 mt-2 border-2 rounded-sm" 
                        value={cost_category} 
                        onChange={handleSelectCostCategory}
                        >
                    <option value="">Select Here</option>
                    <option value={ TRIP_COST_CATEGORY.BACKPACKER_TRIP }>Backpacker Trip</option>
                    <option value={ TRIP_COST_CATEGORY.BUDGET_TRIP }>Budget Trip</option>
                    <option value={ TRIP_COST_CATEGORY.REGULAR_TRIP }>Regular Trip</option>
                    <option value={ TRIP_COST_CATEGORY.PREMIUM_TRIP }>Premium Trip</option>
                </select>
                
                <div>
                    <label className="block font-semibold mt-2"/>Durasi
                    <div className="flex w-3/12">
                        <Input 
                            id="duration_day"
                            placeholder="0"
                            onChange={handleChangeText}
                            />
                        <p className="pt-6 ml-2 mr-2">Hari</p>
                        <Input 
                            id="duration_night"
                            placeholder="0"
                            onChange={handleChangeText}
                            />
                        <p className="pt-6 ml-2 mr-2">Malam</p>
                    </div>
                </div>

                <div>
                    <div className="flex w-3/12">
                        <p className="pt-6 ml-2 mr-2">Minimal</p>
                        <Input 
                            id="min_quota"
                            placeholder="0"
                            onChange={handleChangeText}
                            />
                        <p className="pt-6 ml-2 mr-2">Orang</p>
                    </div>
                </div>
                
                <div>
                    <label className="block font-semibold mt-2"/>Itinerary
                    <div className="shadow rounded-md border-0 bg-white mt-1">
                    <Editor
                        editorState={itinerary}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleItinerary}
                    />
                    </div>
                </div>

                <div>
                    <label className="block font-semibold mt-2"/>Paket Termasuk
                    <div className="shadow rounded-md border-0 bg-white mt-1">
                    <Editor
                        editorState={include}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleInclude}
                    />
                    </div>
                </div>

                <div>
                    <label className="block font-semibold mt-2"/>Paket Tidak Termasuk
                    <div className="shadow rounded-md border-0 bg-white mt-1">
                    <Editor
                        editorState={exclude}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleExclude}
                    />
                    </div>
                </div>

                <div>
                    <label className="block font-semibold mt-2"/>Syarat & Ketentuan
                    <div className="shadow rounded-md border-0 bg-white mt-1">
                    <Editor
                        editorState={tnc}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={handleTnc}
                    />
                    </div>
                </div>

                <div className="border-2 mt-4 pl-4 pb-4">
                    <div className="mr-4">{priceItems}</div>
                    <Button title="Add Price Options" onClick={handleIncreasePriceOption}/>
                    <Button title="Reset Price Options" onClick={
                        () => {
                            priceItems = []
                            handleResetPriceOption()
                        }
                    }
                    model='inactive'/>
                </div>

                <div className="border-2 mt-4">
                <h1 className="text-xl w-full m-auto text-center mt-3">Featured Images</h1>
                    {
                        image_1 ? (
                            <div className="flex w-full m-auto justify-center">
                                <img className="border border-blue-400 w-96 h-40 m-5 mb-0 object-cover" src={ image_url_1 } alt='Destination'/>
                                <div className="h-10 mt-10">
                                    <Button title="CANCEL" id="1" model="inactive" onClick={(e) => handleImageDelete(e)} />
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <Input type="file" model="banner" id="1" onChange={(e) => handleImageChange(e)}/>
                            </Fragment>
                        )
                    }
                    {
                        image_2 ? (
                            <div className="flex w-full m-auto justify-center">
                                <img className="border border-blue-400 w-96 h-40 m-5 object-cover" src={ image_url_2 } alt='Destination'/>
                                <div className="h-10 mt-10">
                                    <Button title="CANCEL" id="2" model="inactive" onClick={(e) => handleImageDelete(e)} />
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <Input type="file" model="banner" id="2" onChange={(e) => handleImageChange(e)}/>
                            </Fragment>
                        )
                    }
                    {
                        image_3 ? (
                            <div className="flex w-full m-auto justify-center">
                                <img className="border border-blue-400 w-96 h-40 m-5 object-cover" src={ image_url_3 } alt='Destination'/>
                                <div className="h-10 mt-10">
                                    <Button title="CANCEL" id="3" model="inactive" onClick={(e) => handleImageDelete(e)} />
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <Input type="file" model="banner" id="3" onChange={(e) => handleImageChange(e)}/>
                            </Fragment>
                        )
                    }
                    {
                        image_4 ? (
                            <div className="flex w-full m-auto justify-center">
                                <img className="border border-blue-400 w-96 h-40 m-5 object-cover" src={ image_url_4 } alt='Destination'/>
                                <div className="h-10 mt-10">
                                    <Button title="CANCEL" id="4" model="inactive" onClick={(e) => handleImageDelete(e)} />
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <Input type="file" model="banner" id="4" onChange={(e) => handleImageChange(e)}/>
                            </Fragment>
                        )
                    }
                    {
                        image_5 ? (
                            <div className="flex w-full m-auto justify-center">
                                <img className="border border-blue-400 w-96 h-40 m-5 object-cover" src={ image_url_5 } alt='Destination'/>
                                <div className="h-10 mt-10">
                                    <Button title="CANCEL" id="5" model="inactive" onClick={(e) => handleImageDelete(e)} />
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <Input type="file" model="banner" id="5" onChange={(e) => handleImageChange(e)}/>
                            </Fragment>
                        )
                    }
                </div>
                
                <div class="flex pt-6">
                  <p className="ml-2 mr-5">Price View Option:</p>
                  <Input
                    id="price_view_option"
                    type="radio"
                    value={PRICE_VIEW.ORIGIN}
                    checked={price_view_option === PRICE_VIEW.ORIGIN}
                    onChange={handleChangeText}
                    label="Differ by Origin"
                  />
                  <Input
                    id="price_view_option"
                    type="radio"
                    value={PRICE_VIEW.PERSON}
                    checked={price_view_option === PRICE_VIEW.PERSON}
                    onChange={handleChangeText}
                    label="Differ by Person Amount"
                  />
                  <Input
                    id="price_view_option"
                    type="radio"
                    value={PRICE_VIEW.BOTH}
                    checked={price_view_option === PRICE_VIEW.BOTH}
                    onChange={handleChangeText}
                    label="Differ by Both"
                  />
                </div>

                <div>
                  <div className="flex w-5/12">
                      <p className="pt-6 ml-2 mr-2">DP Minimal </p>
                      <Input 
                          id="dp_minimal"
                          placeholder="50% / Rp 1.000.000"
                          onChange={handleChangeText}
                          value={dp_minimal}
                          />
                  </div>
                </div>

                <div>
                  <div className="flex w-5/12">
                      <p className="pt-6 ml-2 mr-2">DP Due Date </p>
                      <Input 
                          id="dp_due_date"
                          placeholder="H-30"
                          onChange={handleChangeText}
                          value={dp_due_date}
                          />
                  </div>
                </div>

                <div>
                  <div className="flex w-5/12">
                      <p className="pt-6 ml-2 mr-2">Full Pay Due Date </p>
                      <Input 
                          id="full_pay_due_date"
                          placeholder="H-14"
                          onChange={handleChangeText}
                          value={full_pay_due_date}
                          />
                  </div>
                </div>


                <div class="flex pt-6">
                    <p className="ml-2 mr-5">Refund Options:</p>
                    <Input
                      id="refund_option"
                      type="radio"
                      value={REFUND_OPTION.MAX_7}
                      checked={refund_option === REFUND_OPTION.MAX_7}
                      onChange={handleChangeText}
                      label="7 Days Period"
                    />
                    <Input
                      id="refund_option"
                      type="radio"
                      value={REFUND_OPTION.MAX_14}
                      checked={refund_option === REFUND_OPTION.MAX_14}
                      onChange={handleChangeText}
                      label="14 Days Period"
                    />
                    <Input
                      id="refund_option"
                      type="radio"
                      value={REFUND_OPTION.MAX_30}
                      checked={refund_option === REFUND_OPTION.MAX_30}
                      onChange={handleChangeText}
                      label="30 Days Period"
                    />
                </div>

                {
                    onProgressSubmit ? (
                        <Button loading="true" onClick={handleSubmit}/>
                    ) : (
                        <Button title="Submit" onClick={handleSubmit}/>
                    )
                }
            </div>
        </div>
    )
  }
}

const reduxState = (state) => ({
    destinationList : state.destinations
})

const reduxDispatch = (dispatch) => ({
    getDestinations     : () => dispatch(getAllDestinationsAPI()),
    addTour :   (data) => dispatch(addTourDataAPI(data)),
    uploadImage:   (data) => dispatch(addTourImage(data)),
    addMapping: (key, val) => dispatch(createMapValue(key, val))
})

export default withRouter(connect(reduxState, reduxDispatch)(ExclusiveTourCreate));
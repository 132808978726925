import React from 'react';

const Banner = ({img_url, redirect_url}) => {
  return (
    <div className="relative flex flex-col-reverse w-full h-56 md:h-108 flex bg-blue-400 cursor-pointer" onClick={ () => {
      if(redirect_url !== undefined){
        window.location.href = redirect_url.replace( 'https://', '' )
        // console.log("HAHA",redirect_url.replace( 'https://', '' ))
      }
    }}>
      <img className="absolute inset-0 h-full w-full object-cover" src={img_url} alt='Destination'/>
      {/* <div className="absolute inset-0 h-full bg-gradient-to-t from-gray-900 via-transparent"></div> */}
    </div>
  )
}

export default Banner;
export const ENUM_STATE = {
  ACTIVE: 1,
  INACTIVE: 0
}

export const TRIP_TYPE = {
  OPEN_TRIP: 1,
  PRIVATE_TRIP: 2,
  FAMILY_TRIP: 3
}

export const TRIP_COST_CATEGORY = {
  BACKPACKER_TRIP: 0,
  BUDGET_TRIP: 1,
  REGULAR_TRIP: 2,
  PREMIUM_TRIP: 3
}

export const PRICE_VIEW = {
  ORIGIN: '1',
  PERSON: '2',
  BOTH: '3'
}

export const REFUND_OPTION = {
  MAX_14: '1',
  MAX_30: '2',
  MAX_7: '3',
  CUSTOM: '4'
}

export const PAYMENT_STATE = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED'
}
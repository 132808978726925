import axios from "axios";
import moment from "moment/moment"
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../../../../../components/atoms/Button";
import CustomDatepicker from "../../../../../components/atoms/Datepicker";
import Input from "../../../../../components/atoms/Input";
import { getActiveToursDataAPI } from "../../../../../config/redux/action/tour";
import { toIDR } from "../../../../../utils/toIDR";

class ExclusivePaymentGenerate extends Component{
  state={
    tourName: '',
    quantity: 0,
    tourTotalPrice: '',
    customerName: '',
    customerEmail: '',
    customerPhoneNumber: '',
    isLoading: false,
    paymentMethod: '',
    errorMessage: '',
    expiryHour: '',
    departureDate: '',
    departureOrigin: '',
    paymentTerm: '',
  }

  handleChangeText = (e) => {
    if(e.target.id === 'tourTotalPrice'){
      this.setState({
        tourTotalPrice: toIDR(e.target.value)
      })
    }else{
      this.setState({
        [e.target.id]: e.target.value
      })
    }
    // console.log(this.state.tourTotalPrice)
  }

  handleSelectTour = (e) =>{
    this.setState({
      tourName: e.target.value
    })
  }

  handleSelectDepartureDate = (val) => {
    this.setState({
      departureDate: val
    })
  }

  handleSelectPaymentMethod = (e) =>{
    this.setState({
      paymentMethod: e.target.value
    })
  }

  handleSelectPaymentTerm= (e) =>{
    this.setState({
      paymentTerm: e.target.value
    })
  }

  componentDidMount(){
    const { getTours } = this.props
    getTours()
  }

  generatePayload = () => {
    return {
      tourId: '-',
      tourName: this.state.tourName,
      quantity: parseInt(this.state.quantity),
      departureDate: moment(this.state.departureDate).format('DD/MM/YYYY'),
      departureOrigin: this.state.departureOrigin,
      termPayment: this.state.paymentTerm,
      tourTotalPrice: parseInt(this.state.tourTotalPrice.replaceAll(".","")),
      customerName: this.state.customerName,
      customerEmail: this.state.customerEmail,
      customerPhoneNumber: this.state.customerPhoneNumber,
      paymentMethod: this.state.paymentMethod,
      expiryHour: parseInt(this.state.expiryHour)
    }
  }

  handleSubmitGeneratePayment = async () => {
    this.setState({
      isLoading: true
    })
    try{
      const payload = this.generatePayload()
      await axios.post(
        `${process.env.REACT_APP_BE_HOST}/payments/generate-payment-link`, 
        payload
        )
      this.props.history.push('/exclusive/payment/list')
    } catch(err) {
      this.setState({
        isLoading: false,
        errorMessage: `Fix Your Input Data`
      })
    }
    
  }
  
  render(){
    const { tourList } = this.props
    const { tourName, isLoading, paymentMethod, tourTotalPrice, errorMessage, paymentTerm } = this.state
    const { 
      handleSelectTour, 
      handleChangeText,
      handleSubmitGeneratePayment,
      handleSelectPaymentMethod,
      handleSelectDepartureDate,
      handleSelectPaymentTerm } = this

    return(
      <div className="w-full bg-gray-50 min-h-screen">
        <div className="w-full mb-5">
          <h1 className="text-center text-gray-900 text-4xl pt-5">Generate Payment</h1>
        </div>

        
        <div className="w-2/3 m-auto">
          {/* Payment Method Selection */}
          <label className="block font-semibold mt-2"/>Payment Method :
          <select className="ml-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={paymentMethod} 
                  onChange={handleSelectPaymentMethod}>
            <option value="">Select Here</option>
            <option value="CREDIT_CARD">Credit Card</option>
            <option value="PERMATA">VA - Permata</option>
            <option value="BRI">VA - BRI</option>
            <option value="BSI">VA - BSI</option>
            <option value="BNI">VA - BNI</option>
            <option value="MANDIRI">VA - MANDIRI</option>
          </select>

          {/* Payment Term Selection */}
          <label className="block font-semibold mt-2"/>Payment Term :
          <select className="ml-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={paymentTerm} 
                  onChange={handleSelectPaymentTerm}>
            <option value="">Select Here</option>
            <option value="Deposit">Deposit</option>
            <option value="Down Payment">Down Payment</option>
            <option value="Full Payment">Full Payment</option>
          </select>

          {/* expiryHour */}
          <label className="block font-semibold mt-3"/>Expired At :
          <div className="w-2/12 flex">
            <Input 
              id="expiryHour"
              placeholder="1"
              onChange={handleChangeText}
              />
            <p className="pt-6 ml-2 mr-2">Hour</p>
          </div>

          {/* tourName */}
          <label className="block font-semibold mt-2"/>Tour Name :
          <select className="ml-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={tourName} 
                  onChange={handleSelectTour}>
            <option value="">Select Here</option>
          {
              tourList.map(tourDetail => {
                  return(
                      <option key={tourDetail.id} value={tourDetail.data.name}>{tourDetail.data.title}</option>
                  )
              })
          }
          </select>

          {/* Quantity */}
          <label className="block font-semibold mt-3"/>Quantity :
          <div className="w-2/12 flex">
            <Input 
              id="quantity"
              placeholder="0"
              onChange={handleChangeText}
              />
            <p className="pt-6 ml-2 mr-2">Pax</p>
          </div>

          {/* DepartureDate */}
          <div className="mt-5 w-3/12">
          <label className="block font-semibold"/>Waktu Keberangkatan:
            <CustomDatepicker onPick={handleSelectDepartureDate}/>
          </div>

          {/* DepartureOrigin */}
          <label className="block font-semibold mt-3"/>Tempat Keberangkatan :
          <Input 
            id="departureOrigin"
            placeholder="Jakarta"
            onChange={handleChangeText}
            />

          {/* Total Amount */}
          <label className="block font-semibold mt-3"/>Total Nominal :
          <div className="w-3/12 flex">
            <Input 
              id="tourTotalPrice"
              placeholder="500000"
              onChange={handleChangeText}
              value={tourTotalPrice}
              changingValue
              />
            <p className="pt-6 ml-2 mr-2">Rupiah</p>
          </div>

          {/* Customer Name */}
          <label className="block font-semibold mt-3"/>Customer Name :
          <Input 
            id="customerName"
            placeholder="Farhan Farasdak"
            onChange={handleChangeText}
            />

          {/* Customer Email */}
          <label className="block font-semibold mt-3"/>Customer Email :
          <Input 
            id="customerEmail"
            placeholder="farhan.farasdak@gmail.com"
            onChange={handleChangeText}
            />

          {/* Customer PhoneNumber */}
          <label className="block font-semibold mt-3"/>Customer Phone Number :
          <Input 
            id="customerPhoneNumber"
            placeholder="087888..."
            onChange={handleChangeText}
            />

          <div className="mt-3">
            <Button loading={isLoading} title='Generate Payment' onClick={handleSubmitGeneratePayment}/>
            <p className="text-red-500">{errorMessage}</p>
          </div>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
  tourList    : state.tours
})

const reduxDispatch = (dispatch) => ({
  getTours: () => dispatch(getActiveToursDataAPI())
})

export default connect(reduxState, reduxDispatch)(ExclusivePaymentGenerate)
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from '../Dashboard'
import { Provider } from 'react-redux'
import { store } from '../../../config/redux'
import SimpleNotes from '../SimpleNotes';
import ExclusiveDestination from '../Exclusive/Destination'
import Destination from '../Destination';
import Navbar from '../../organisms/Navbar';
import Footer from '../../organisms/Footer';
import Exclusive from '../Exclusive';
import ExclusiveBanner from '../Exclusive/Banner'
import TourDetail from '../TourDetail';
import ExclusiveTourCreate from '../Exclusive/Tour/Create';
import ExclusiveTourManage from '../Exclusive/Tour/Manage';
import ExclusiveTourEdit from '../Exclusive/Tour/Edit';
import ExclusiveTourDuplicate from '../Exclusive/Tour/Duplicate';
import ExclusiveLogin from '../Exclusive/Login';
import OurTour from '../OurTour';
import ScrollToTop from '../../../utils/ScrollToTop';
import ExclusivePaymentGenerate from '../Exclusive/Payment/Generate';
import ExclusivePaymentList from '../Exclusive/Payment/List';
import ExclusiveCreateInvoice from '../Exclusive/Invoice/Create';
import ExclusiveManageInvoice from '../Exclusive/Invoice/Manage/index.js';
import ExclusiveEditInvoice from '../Exclusive/Invoice/Edit';


function App() {
  return (
    <div className='font-montserrat'>
      <Provider store={store}>
        <Router>
          <Navbar />
          <ScrollToTop/>
          <Route path="/" exact component={Dashboard} />
          <Route path="/simple-notes" exact component={SimpleNotes} />
          <Route path="/destinasi-wisata" exact component={Destination} />
          <Route path="/tour/:id" exact component={TourDetail} />
          <Route path="/our-tours" exact component={OurTour}/>

          <Route path="/exclusive" exact component={Exclusive} />
          <Route path="/exclusive/login" exact component={ExclusiveLogin}/>
          <Route path="/exclusive/destinasi-wisata" exact component={ExclusiveDestination} />
          <Route path="/exclusive/banner" exact component={ExclusiveBanner} />
          <Route path="/exclusive/tour/new" exact component={ExclusiveTourCreate} />
          <Route path="/exclusive/tour/manage" exact component={ExclusiveTourManage} />
          <Route path="/exclusive/tour/edit/:id" exact component={ExclusiveTourEdit} />
          <Route path="/exclusive/tour/duplicate/:id" exact component={ExclusiveTourDuplicate} />
          <Route path="/exclusive/payment/generate" exact component={ExclusivePaymentGenerate} />
          <Route path="/exclusive/payment/list" exact component={ExclusivePaymentList} />
          <Route path="/exclusive/invoice/new" exact component={ExclusiveCreateInvoice} />
          <Route path="/exclusive/invoice/manage" exact component={ExclusiveManageInvoice} />
          <Route path="/exclusive/invoice/edit/:id" exact component={ExclusiveEditInvoice} />
          
          <Footer />
        </Router>
      </Provider>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react"
import { retrieveInvoices } from "../../../../../config/redux/action/offline_invoice"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { sumIDR } from "../../../../../utils/toIDR"

const ExclusiveManageInvoice = () => {
  const [invoiceList, setInvoiceList] = useState([])
  
  const retrieveInvoiceList = async () => {
    const data = await retrieveInvoices()
    setInvoiceList(data)
  }

  useEffect(() => {
    retrieveInvoiceList()
  },[])

  const getUnpaidAmount = (invoice) => {
    let paidPayment = 0
    invoice.recordPayments.forEach(recordPayment => {
      const price = recordPayment['price'] ? recordPayment['price'] : ''
      const status = recordPayment['status'] ? recordPayment['status'] : ''
      if(status === 'LUNAS'){
        paidPayment = sumIDR(paidPayment, price)
      }
    })
    return paidPayment
  }

  const getUpcomingPaymentDate = (invoice) => {
    let result = ''
    invoice.recordPayments.forEach(recordPayment => {
      console.log(recordPayment)
      if(recordPayment.status === 'MENUNGGU PEMBAYARAN' && result === ''){
        result = recordPayment.time
      }
    })
    return result
  } 
  
  return(
    <div className="w-full min-h-screen">
      <div className="my-10 ml-5 md:ml-20">
        <Link to={"/exclusive/invoice/new"}>
          <div className="px-2 py-2 bg-yellow-600 hover:bg-yellow-500 inline text-white rounded-sm cursor-pointer">
            + Create New Invoice
          </div>
        </Link>
      </div>
      <div className="mt-10 ml-5 md:ml-20 mb-10 overflow-scroll">
        <table className="table-auto">
          <thead className="border-2 border-gray-800">
            <tr>
              <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Invoice ID</th>
              <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Customer</th>
              <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Invoice Amount</th>
              <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Status</th>
              <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center px-2">Unpaid Amount</th>
              <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center px-2">Next Payment Date</th>
            </tr>
          </thead>
          <tbody>
            {invoiceList.map((invoice, index) => (
              <tr key={index}>
                <td className="text-sm md:text-md border-2 border-blue-500 px-5 text-blue-500 hover:text-blue-400 cursor-pointer" onClick={() => {
                  window.location.href=(`/exclusive/invoice/edit/${invoice.invoiceCounter}` )
                }}>{invoice.invoiceId}</td>
                <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">{invoice.customer}</td>
                <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">Rp {invoice.invoiceAmount}</td>
                <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2 font-semibold">
                {
                  invoice.paymentStatus === 'LUNAS' ? <span className="pl-2 text-green-600">LUNAS</span> : null
                } 
                {
                  invoice.paymentStatus === 'MENUNGGU PEMBAYARAN' ? <span className="pl-2 text-yellow-600">MENUNGGU PEMBAYARAN</span> : null
                }
                {
                  invoice.paymentStatus === 'MENUNGGU PELUNASAN' ? <span className="pl-2 text-yellow-600">MENUNGGU PELUNASAN</span> : null
                }
                {
                  invoice.paymentStatus === 'DIBATALKAN' ? <span className="pl-2 text-red-600">DIBATALKAN</span> : null
                }
                </td>
                <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">
                  {
                    invoice.paymentStatus === 'MENUNGGU PELUNASAN' ? `Rp ${getUnpaidAmount(invoice)}` : 'Rp 0'
                  }
                </td>
                <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">
                  {
                    invoice.paymentStatus === 'MENUNGGU PELUNASAN' ? `Rp ${getUpcomingPaymentDate(invoice)}` : '-'
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ExclusiveManageInvoice
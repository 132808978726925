import { Component } from "react";
import { getPaymentList } from "../../../../../service/payment/retrieve";
import { toIDR } from "../../../../../utils/toIDR";
import { mapPaymentStatus } from "../../../../../utils/mapPaymentStatus";
import PaymentDetailModal from "../../../../organisms/PaymentDetailModal";

class ExclusivePaymentList extends Component{
  state={
    paymentList: [],
    modalShown: false,
    paymentClickedData: {}
  }

  async componentDidMount(){
    const data = await getPaymentList()
    this.setState({
      paymentList: data
    })
  }

  showPaymentDetail = (paymentData) => {
    this.setState({
      modalShown: true,
      paymentClickedData: paymentData
    })
  }

  closePaymentDetail = () => {
    this.setState({
      modalShown: false,
      paymentClickedData: {}
    })
  }
  
  render(){
    const { paymentList, modalShown, paymentClickedData } = this.state
    const { showPaymentDetail, closePaymentDetail } = this
    return(
      <div className="w-full bg-gray-50 min-h-screen">
        { modalShown ? (
          <PaymentDetailModal 
            toggleModal={closePaymentDetail}
            paymentDetail={paymentClickedData}/>
        ) : null }
        <div className="w-full mb-5">
          <h1 className="text-center text-gray-900 text-4xl pt-5">Payment List</h1>
        </div>
        <div className="w-full m-auto">
          <table className="table-auto m-auto bg-white">
            <thead className="border-2 border-gray-800">
              <tr>
                <th className="text-sm md:text-xl border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center px-4 py-3">Payment ID</th>
                <th className="text-sm md:text-xl border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center px-4 py-3">Date Created</th>
                <th className="text-sm md:text-xl border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center px-4 py-3">Customer Name</th>
                <th className="text-sm md:text-xl border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center px-4 py-3">Amount</th>
                <th className="text-sm md:text-xl border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100 text-center text-center px-4 py-3">Status</th>
              </tr>
            </thead>
            <tbody>
              { paymentList.map(payment => (
                <tr key={payment.id}>
                  <td className="text-sm md:text-xl border-2 border-blue-500 text-center px-4 py-2 text-blue-600 hover:text-blue-300 cursor-pointer" onClick={() => showPaymentDetail(payment)}>{payment.paymentNumber}</td>
                  <td className="text-sm md:text-xl border-2 border-blue-500 text-center px-4 py-2">{(new Date(payment.createdAt)).toLocaleString()}</td>
                  <td className="text-sm md:text-xl border-2 border-blue-500 text-center px-4 py-2">{payment.customerName}</td>
                  <td className="text-sm md:text-xl border-2 border-blue-500 text-center px-4 py-2">Rp{toIDR(payment.chargedAmount)}</td>
                  <td className="text-sm md:text-xl border-2 border-blue-500 text-center px-4 py-2">{mapPaymentStatus(payment.status)}</td>
                </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ExclusivePaymentList
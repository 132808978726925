import React from 'react';

const Textarea = ({id, value, onChange, label, placeholder, size}) => {
  if (size==='medium'){
    return (
      <div>
        <label className="block font-semibold mt-2"/>{label}
        <textarea id={id} value={value} onChange={onChange} placeholder={placeholder} className="border w-full h-32 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md"/>
      </div>
    )
  }
  return (
    <div>
      <label className="block font-semibold mt-2"/>{label}
      <textarea id={id} value={value} onChange={onChange} placeholder={placeholder} className="border w-full h-96 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md"/>
    </div>
  )
}

export default Textarea;
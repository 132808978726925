import React from 'react';
import { PRICE_VIEW } from '../../../utils/enums';
import { toIDR } from '../../../utils/toIDR';

const PriceDetail = ({include, exclude, prices, tour_dates, price_view_option}) => {
  return (
    <div>
      <h2 className="md:text-lg font-semibold text-center mb-2">HARGA MULAI DARI : </h2>
      <table className="table-auto m-auto">
        <thead className="border-2 border-gray-800">
        { price_view_option === PRICE_VIEW.ORIGIN ? 
          <tr>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Tempat Keberangkatan</th>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Harga / Orang</th>
          </tr>
        : null }
        { price_view_option === PRICE_VIEW.PERSON ? 
          <tr>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Minimal Pemesanan</th>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Harga / Orang</th>
          </tr>
        : null }
        { price_view_option === PRICE_VIEW.BOTH ? 
          <tr>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Tempat Keberangkatan</th>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Minimal Pemesanan</th>
            <th className="text-sm md:text-lg border-2 border-blue-500 pl-5 pr-5 bg-blue-500 text-gray-100">Harga / Orang</th>
          </tr>
        : null }
        </thead>
        <tbody>
          {
            prices.map(price => {
              if( price_view_option === PRICE_VIEW.ORIGIN ){
                return(
                  <tr key={price.id}>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{price.origin}</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">Rp{toIDR(price.price)}</td>
                  </tr>
                )
              }
              if( price_view_option === PRICE_VIEW.PERSON ){
                return(
                  <tr key={price.id}>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{price.minquota}</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">Rp{toIDR(price.price)}</td>
                  </tr>
                )
              }
              if( price_view_option === PRICE_VIEW.BOTH ){
                return(
                  <tr key={price.id}>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{price.origin}</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">{price.minquota}</td>
                    <td className="text-sm md:text-lg border-2 border-blue-500 text-center">Rp{toIDR(price.price)}</td>
                  </tr>
                )
              }
            })
          }
        </tbody>
      </table>

      <div className='mt-5'>
        <h2 className="md:text-lg font-medium">Pilihan Waktu Tour : </h2>
        <ul className='list-disc ml-3'>
          {
            tour_dates.map(date => {
              return(
                <li key={date} className='text-sm md:text-lg ml-5'>{date}</li>
              )
            })
          }
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="md:text-lg font-medium">Paket Termasuk :</h2>
        <div className="text-sm md:text-lg" dangerouslySetInnerHTML={{ __html: include }} />
      </div>

      <div className="mt-5">
        <h2 className="md:text-lg font-medium">Paket Tidak Termasuk :</h2>
        <div className="text-sm md:text-lg" dangerouslySetInnerHTML={{ __html: exclude }} />
      </div>
    </div>
  )
}

export default PriceDetail;
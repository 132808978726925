import { useEffect } from "react"
import { useState } from "react"
import Button from "../../../components/atoms/Button"
import Input from "../../../components/atoms/Input"
import { toIDR } from "../../../utils/toIDR"
import { PRICE_VIEW } from "../../../utils/enums"
import CustomDatepicker from "../../../components/atoms/Datepicker"
import Counter from "../../../components/atoms/Counter"
import moment from "moment/moment"
import axios from "axios"
import { isNumeric, validateEmail } from "../../../utils/validateFormat"


const CheckoutModal = ({ 
  minQuota,
  prices,
  priceViewOption,
  tourId,
  tourName,
  toggleFunc }) => {

  const [name, setName] = useState()
  const [phone, setPhone] = useState('')
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [email, setEmail] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState()
  const [totalAmount, setTotalAmount] = useState(0)
  const [tourDate, setTourDate] = useState()
  const [tourOrigin, setTourOrigin] = useState()
  const [disableButton, setDisableButton] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState()
  const [paymentFee, setPaymentFee] = useState()

  const findPriceObj = () => {
    let priceObj = {}
    if(priceViewOption === PRICE_VIEW.ORIGIN) {
      for(let i = 0; i < prices.length ; i++){
        if(prices[i].origin === tourOrigin){
          priceObj = prices[i]
        }
      }
      return priceObj
    }
    else if(priceViewOption === PRICE_VIEW.PERSON) {
      for(let i = 0; i < prices.length; i++){
        const quota = prices[i].minquota
        if(quota.length <= 2) {
          if(parseInt(quota) <= quantity) {
            priceObj = prices[i]
          }
        }else {
            // TO DO REFACTOR LATER
            // NEVER ALLOW INSERTING INVALID QUANTITY
            // INVALID QUANTITY DEFINITION
            // QUANTITY < MINQUOTA
            // QUANTITY NOT NUMBER
            priceObj = {
              price: '0'
            }
            return priceObj
        }
        
      }
    }
    return priceObj
  }

  const calculateTotalPrice = () => {
    if(quantity >= parseInt(minQuota)){
      if(priceViewOption === PRICE_VIEW.ORIGIN || priceViewOption === PRICE_VIEW.PERSON){
        let priceObj = findPriceObj()
        if(priceObj.price){
          const individualPrice = parseInt(priceObj.price.replaceAll('.',''))
          const totalPrice = individualPrice * quantity
          setTotalAmount(totalPrice)
        }
      }
      else {
        // TODO HERE
      }
    }else {
      setTotalAmount(0)
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    const payload = {
      tourId: tourId,
      tourName: tourName,
      quantity: quantity,
      departureDate: moment(tourDate).format('DD/MM/YYYY'),
      departureOrigin: tourOrigin,
      customerName: name,
      customerPhoneNumber: phone,
      customerEmail: email,
      tourTotalPrice: totalAmount,
      paymentMethod: paymentMethod
    }

    const resp = await axios.post(
      `${process.env.REACT_APP_BE_HOST}/payments`,
      payload
    )
    
    if(resp.status === 201){
      window.location.replace(resp.data.checkoutUrl)
    }
  }

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity)
  }

  // Payment Fee Calculation
  useEffect(() => {
    switch (paymentMethod) {
      case 'CREDIT_CARD':
        return setPaymentFee('3.5%');
      case 'BCA':
        return setPaymentFee('IDR 5.000');
      case 'PERMATA':
        return setPaymentFee('IDR 5.000');
      case 'BRI':
        return setPaymentFee('IDR 5.000');
      case 'BSI':
        return setPaymentFee('IDR 5.000');
      case 'BNI':
        return setPaymentFee('IDR 5.000');
      case 'MANDIRI':
        return setPaymentFee('IDR 5.000');
      default:
        return setPaymentFee(null);
    }
  },[paymentMethod])

  // Disabling button
  useEffect(() => {
    if(
      tourOrigin && 
      paymentMethod && 
      name && 
      phone &&
      email && !invalidEmail && !invalidPhone){
        setDisableButton(false)
      }else{
        setDisableButton(true)
      }
  }, [tourOrigin, paymentMethod, name, phone, email, invalidEmail, invalidPhone])

  // Calculating Price
  useEffect(() => {
    calculateTotalPrice()
  }, [quantity, tourOrigin])

  // Predefined quantity and tour date
  useEffect(() => {
    setQuantity(minQuota)
    const today = new Date()
    setTourDate(today)
    if(priceViewOption === PRICE_VIEW.PERSON){
      setTourOrigin('Original Destination')
    }
  },[minQuota])

  // valid email format
  useEffect(() => {
    if(email === undefined || email === ''){
      setInvalidEmail(false)
    }else{
      const valid = validateEmail(email)
      setInvalidEmail(!valid)
    }  
  }, [email])

  // valid phone format
  useEffect(() => {
    if(phone === undefined || phone === ''){
      setInvalidPhone(false)
    }else{
      const valid = isNumeric(phone)
      setInvalidPhone(!valid)
    }
  },[phone])

  return(
    <div className="overflow-auto fixed z-50 w-full m-auto bg-black bg-opacity-20 h-full top-0">
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">       
      <div className="relative bg-white rounded-lg shadow">
          <div className="flex bg-blue-500 p-4 rounded-t border-b">
            <h3 className="text-xl text-white font-semibold">
              Order Now
            </h3>
            <button 
                type="button" 
                className="text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" 
                onClick={toggleFunc}>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span className="sr-only">Close modal</span>
              </button>
          </div>
            
          <div className="pl-6 pr-6 pb-3 text-sm">
            <div className="flex mt-2">
              <p className="pt-2 mr-3">Orang</p>
              <div className="w-1/2">
                <Counter 
                  minThreshold={parseInt(minQuota)}
                  onChange={handleQuantityChange}/>
              </div>
              
              
            </div>
            <div className="mt-5">
            <label className="block font-semibold"/>Waktu Keberangkatan:
              <CustomDatepicker onPick={setTourDate}/>
            </div>

            {/* Customer Departure Selection */}
            {
              priceViewOption === PRICE_VIEW.ORIGIN ? (
                <div>
                  <label className="block font-semibold mt-2"/>Tempat Keberangkatan:
                  <select className="block mt-1 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 w-full"
                          onChange={(e) => {
                            setTourOrigin(e.target.value)
                          } }> 
                    <option value="">Select Here</option>
                    {
                      prices.map((price) => {
                        return(
                          <option key={price.origin} value={price.origin}>{`From ${price.origin}`}</option>
                        )
                      })
                    } 
                  </select>
                </div>
              ) : null
            }

            
            {/* Payment Method */}
            <div>
              <label className="block font-semibold mt-2"/>Metode Pembayaran:
              <select className="block mt-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                      onChange={(e) => {
                        setPaymentMethod(e.target.value)
                      } }>
                <option value="">Select Here</option>
                <option value={"BNI"}>VA - BNI</option>
                <option value={"MANDIRI"}>VA - Mandiri</option>
                <option value={"BSI"}>VA - BSI</option>
                <option value={"BRI"}>VA - BRI</option>
                <option value={"PERMATA"}>VA - Permata</option>
              </select>
            </div>
            
            <div className="border-t mt-3">
              <div className="text-sm">
                <Input
                  label='Full Name'
                  placeholder="Your Name"
                  onChange={ e => setName(e.target.value) }
                  />
                <Input
                  label='Phone Number'
                  placeholder="08..."
                  onChange={ e => setPhone(e.target.value) }
                  invalid={invalidPhone}
                  />
                <Input
                  label='Email'
                  placeholder="youremail@domain.com"
                  onChange={ e => setEmail(e.target.value) }
                  invalid={invalidEmail}
                  />
              </div>
            </div>
            <div className='w-full mt-5'>
              <p>Our team will contact you based on your data input as contact person on your tour.</p>
            </div>
          </div>
            
          <div className="pb-5 border-t border-gray-200 dark:border-gray-600">
            <div className="pt-3 pl-5">
              <p className="text-xl text-yellow-600">IDR {toIDR(totalAmount)}</p>
              {
                paymentFee ? (
                  <p className="text-sm">+ Payment Fee {paymentFee}</p>
                ) : null
              }
            </div>
            <div className="ml-5">
              { loading ? (
                <Button loading={true}/>
              ) : (
                <Button 
                title="Pay Now"
                color="blue"
                onClick={handleSubmit}
                disabled={disableButton}
                loading={isLoading}
                />
              ) }
              {
                disableButton ? (
                  <p className="text-red-500 text-sm">Please Input Your Data Correctly</p>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutModal
const BlogPost = ({thumbnail, title, description, blogUrl}) => {
  return(
    <div className="border shadow overflow-hidden md:w-96 h-80 md:h-96 cursor-pointer"
    onClick={() => {
      window.open(blogUrl, '_blank')
    }}>
      <div className="relative h-40 md:h-52">
        <img className="absolute inset-0 h-40 md:h-52 w-full object-cover" src={thumbnail} alt='Destination'/>
      </div>
      <div>
        <h2 className="text-xl md:text-2xl text-yellow-600 hover:text-yellow-400 font-semibold ml-2">{title}</h2>
        <p className="text-sm ml-2 mt-2">{`${description.substring(0,100)}...`}</p>
      </div>
    </div>
  )
}

export default BlogPost